import React, { useState } from 'react';
import { MainWrapper } from '../../styles';
import { Avatar, MenuItem, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { useTranslation } from 'react-i18next';
import {
  MoreButton,
  MoreButtonWrapper,
} from '../../../../../components/Card/styles';
import { BusinessRounded, MoreHorizRounded } from '@mui/icons-material';
import { StyledMenu } from '../../../../../components/Card/MoreMenu/MoreMenu';
import { BusinessJobAdsModel } from '../../../../../interfaces/JobOffers.model';
import dayjs from 'dayjs';
import DeleteJobAd from './DeleteAdWarning';
import { useNavigate } from 'react-router-dom';
import { useRemoveAdFromDraftMutation } from '../../../../../rq/hooks/useJob.mutation';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

const JobOffer = ({ job }: { job: BusinessJobAdsModel }) => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { mutate: removeAdFromDraft, isLoading: removeAdFromDraftLoading } =
    useRemoveAdFromDraftMutation(Number(job.id), {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-business-job-ads']);
        handleClose();
        toast.success(t('job_published_successfully'));
      },
    });

  /**
   * handlers
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <MainWrapper>
        <div
          className='cursor-pointer flex lg:flex-row flex-col justify-between items-start border-b-2 border-solid border-[#EDEDED]'
          onClick={() => {
            if (job.isActive) {
              navigate(`/Business/profile/job-ad/candidates/${job.id}`);
            }
          }}
        >
          <div className='flex gap-1'>
            {job.businessLogoPath ? (
              <Avatar src={job.businessLogoPath} variant='rounded' />
            ) : (
              <Avatar variant='rounded'>
                <BusinessRounded />
              </Avatar>
            )}
            <div className='flex flex-col '>
              <Typography className='text-sm'>{job.businessName}</Typography>
              <Typography className='text-sm font-bold'>{job.title}</Typography>
              <Typography className='text-[#4A4D4B] text-sm'>
                {job.employmentTypes.join(', ')}
              </Typography>
              <div className='flex items-center gap-1'>
                <CalendarTodayOutlinedIcon className='fill-[#C6C6C6] text-sm' />
                <Typography className='text-[#4A4D4B] text-sm'>
                  {t('published_on')}{' '}
                  {dayjs(job.publishDate).format('DD/MM/YYYY')}
                </Typography>
              </div>
            </div>
          </div>
          <div className='flex gap-1 items-baseline'>
            <Typography className='border-2 border-solid border-[#72CC8B] text-center rounded-full text-sm px-2'>
              {job.status}
            </Typography>
            {job?.isActive && job?.activeDaysLeft && (
              <Typography className='border-2 border-solid border-[#EDEDED] text-center rounded-full text-sm px-2'>
                {job?.activeDaysLeft} {t('days_left')}
              </Typography>
            )}
          </div>
        </div>
        <div className='flex my-2 gap-1'>
          <Typography
            onClick={() => {
              if (job.isActive) {
                navigate(`/Business/profile/job-ad/candidates/${job.id}`);
              }
            }}
            className='text-center rounded-xl w-full bg-[#EEF3FC] text-[#487BD9] py-1 cursor-pointer'
          >
            {job.totalApplications} {t('candidates')}
          </Typography>
          <MoreButtonWrapper>
            <MoreButton onClick={handleClick}>
              <MoreHorizRounded sx={{ color: 'black' }} />
            </MoreButton>
            <StyledMenu
              id='demo-customized-menu'
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => navigate(`/job-offer/${job.id}`)}
                disableRipple
              >
                {t('see_details')}
              </MenuItem>
              <MenuItem onClick={() => setOpenDeleteDialog(true)} disableRipple>
                {t('remove')}
              </MenuItem>
              {job.isDraft && (
                <MenuItem
                  onClick={() => removeAdFromDraft(Number(job.id))}
                  disableRipple
                  disabled={removeAdFromDraftLoading}
                >
                  {t('publish_draft')}
                </MenuItem>
              )}
              {job.isDraft && (
                <MenuItem
                  onClick={() =>
                    navigate(`/Business/profile/edit-draft/${job.id}`)
                  }
                  disableRipple
                  disabled={removeAdFromDraftLoading}
                >
                  {t('edit_draft')}
                </MenuItem>
              )}
            </StyledMenu>
          </MoreButtonWrapper>
        </div>
      </MainWrapper>
      <DeleteJobAd
        id={String(job.id)}
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
      />
    </>
  );
};

export default JobOffer;
