import { FC, useContext, useState } from 'react';
import { BootstrapDialog } from '../../../../../components/BootstrapDialog/BootstrapDialog';
import {
  Avatar,
  Button,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { useTranslation } from 'react-i18next';
import PostJobWarning from './PostJobWarning';
import { AuthContext } from '../../../../../lib/context/AuthContext';
import { useAddJobOfferMutation } from '../../../../../rq/hooks/useJob.mutation';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface PostJobPreviewProps extends DialogProps {
  handleClose: () => void;
  jobDetails: any;
}

const PostJobPreview: FC<PostJobPreviewProps> = ({
  open,
  handleClose,
  jobDetails,
}) => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  const [openPublishWarningJobModal, setOpenPublishWarningJobModal] =
    useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { mutate: addJobOffer, isLoading: addJobOfferLoading } =
    useAddJobOfferMutation();

  /**
   * handlers
   */
  const handleCloseModal = () => handleClose();
  const handleSaveAsDraft = () => {
    addJobOffer(
      {
        ...jobDetails,
        enterpriseDescription:
          jobDetails.enterpriseDescription === 'undefined'
            ? ''
            : jobDetails.enterpriseDescription,
        functionDescription:
          jobDetails.functionDescription === 'undefined'
            ? ''
            : jobDetails.functionDescription,
        jobDescription:
          jobDetails.jobDescription === 'undefined'
            ? ''
            : jobDetails.jobDescription,
        offerDescription:
          jobDetails.offerDescription === 'undefined'
            ? ''
            : jobDetails.offerDescription,
        teaser: jobDetails.teaser === 'undefined' ? '' : jobDetails.teaser,
        isDraft: true,
      },
      {
        onSuccess: () => {
          toast.success(t('job_published_successfully'));
          handleCloseModal();
          navigate('/Business/profile/my-job-offers');
        },
      }
    );
  };

  const descriptions = [
    {
      id: 1,
      name: t('enterprise_description'),
      data: jobDetails.enterpriseDescription,
    },
    {
      id: 2,
      name: t('job_description_title'),
      data: jobDetails.jobDescription,
    },
    {
      id: 3,
      name: t('function_title'),
      data: jobDetails.functionDescription,
    },
    {
      id: 4,
      name: t('offer_title'),
      data: jobDetails.offerDescription,
    },
    {
      id: 5,
      name: t('teaser_title'),
      data: jobDetails.teaser,
    },
  ];

  return (
    <>
      <BootstrapDialog
        aria-labelledby='customized-dialog-title'
        open={open}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            width: '100%',
          },
        }}
      >
        <DialogContent className='flex flex-col gap-2'>
          <div className='flex flex-col justify-center items-center gap-2'>
            <Avatar src={user?.profilePicture} variant='rounded' />
            <Typography className='font-bold text-3xl'>
              {jobDetails.title}
            </Typography>
            <Typography className='font-bold text-xl'>
              {user?.company}
            </Typography>
            <div className='flex'>
              <FmdGoodOutlinedIcon className='fill-[#3F6FE8]' />
              <Typography>{user?.companyAddress}</Typography>
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            {descriptions.map(({ data, name }) => (
              <>
                {data && data !== 'undefined' && (
                  <>
                    <Typography className='font-bold text-base'>
                      {name}
                    </Typography>
                    <Typography className='text-sm'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeURIComponent(data),
                        }}
                      />
                    </Typography>
                  </>
                )}
              </>
            ))}

            <div className='my-2 flex justify-end gap-1'>
              <Button
                variant='contained'
                className='capitalize bg-[#C6C6C6] hover:bg-[#C6C6C6]'
                onClick={handleClose}
              >
                {t('return')}
              </Button>
              <LoadingButton
                variant='contained'
                className='capitalize bg-[#C6C6C6] hover:bg-[#C6C6C6]'
                onClick={handleSaveAsDraft}
                loading={addJobOfferLoading}
              >
                {t('save_as_draft')}
              </LoadingButton>
              <Button
                variant='contained'
                className='capitalize bg-[#72C2CC] hover:bg-[#72C2CC]'
                onClick={() => {
                  setOpenPublishWarningJobModal(true);
                  handleCloseModal();
                }}
                disabled={addJobOfferLoading}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
      <PostJobWarning
        open={openPublishWarningJobModal}
        handleClose={() => setOpenPublishWarningJobModal(false)}
        jobDetails={jobDetails}
      />
    </>
  );
};

export default PostJobPreview;
