import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTemplate from '../../../components/PageTemplate/PageTemplate';
import EditDraft from '../../../modals/components/layouts/business-dashboard/JobOffers/EditDraft';

export default function EditDraftPage() {
  const { t } = useTranslation();
  return (
    <Grid item lg={9} md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <PageTemplate title={t('edit_draft')} content={<EditDraft />} />
    </Grid>
  );
}
