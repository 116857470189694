import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTemplate from '../../../components/PageTemplate/PageTemplate';
import JobOffer from '../../../modals/components/layouts/student-dashboard/JobOffer/JobOffer';

export default function JobOfferPage() {
  const { t } = useTranslation();
  return (
    <Grid item lg={6} md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <PageTemplate title={t('job_offers')} content={<JobOffer />} />
    </Grid>
  );
}
