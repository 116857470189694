import { useTranslation } from "react-i18next";
import {
  InfoWrapper,
  InfoProp,
  InfoFromDb,
  InfosWrapper,
} from "../../../student-dashboard/ProfileInfo/BasicInfo/styles";
import { MainWrapper, TitleWrapper, Title } from "../../../styles";
import UnlockFieldModal from "../UnlockFieldModal/UnlockFieldModal";
import LockedData from "./LockedData";
import { ButtonsWrapper, CustomButton, UnlockButton } from "./styles";
import useCandidateData from "./userData";
import { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { useParams } from "react-router-dom";
import { downloadCandidateFiles } from "../../../../../../rq/queries";

export default function BasicInformation({ data }: any) {
  const { t } = useTranslation();
  const { id } = useParams();
  const infoProps = useCandidateData(data);
  const [openUnlockModal, setOpenUnlockModal] = useState<boolean>(false);

  const handlePrint = async () => {
    try {
      const blob = await downloadCandidateFiles(Number(id));
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("Failed to fetch the print label:", error);
    }
  };

  const dowloadFiles = () => {
    if (!data.email) {
      setOpenUnlockModal(true);
    } else {
      handlePrint();
    }
  };

  return (
    <>
      <MainWrapper>
        <TitleWrapper>
          <Title>{t("basic_info.title")}</Title>
          {!data.email && (
            <UnlockButton onClick={() => setOpenUnlockModal(true)}>
              {t("unlock_infos")}
              <LockIcon sx={{ fontSize: "15px" }} />
            </UnlockButton>
          )}
        </TitleWrapper>
        <InfosWrapper>
          {infoProps?.map((info) => (
            <InfoWrapper key={info.id}>
              <InfoProp>{info.title}</InfoProp>
              {data.email ? (
                <InfoFromDb>{info.value ?? "N/A"}</InfoFromDb>
              ) : (
                <LockedData />
              )}
            </InfoWrapper>
          ))}
        </InfosWrapper>
        <ButtonsWrapper>
          <div onClick={dowloadFiles}>
            <CustomButton variant="contained" download={true}>
              {t("download_resume")}
            </CustomButton>
          </div>
          <CustomButton
            download={false}
            onClick={(e) => {
              if (data.email) {
                window.location.href = `mailto:${data.email}`;
                e.preventDefault();
              } else {
                setOpenUnlockModal(true);
              }
            }}
          >
            {t("send_email")}
          </CustomButton>
        </ButtonsWrapper>
      </MainWrapper>
      <UnlockFieldModal
        isProfile
        openUnlockModal={openUnlockModal}
        setOpenUnlockModal={setOpenUnlockModal}
        candidateId={data.candidateId}
        candidateUserId={data.userId}
      />
    </>
  );
}
