import { MouseEvent, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import icon_black from '../../../assets/icons/my_first_job_icon_black.png';
import icon from '../../../assets/icons/my_first_job_icon.png';
import { AuthContext } from '../../../lib/context/AuthContext';
import { Person, BusinessRounded } from '@mui/icons-material';
import {
  LogoIcon,
  Name,
  Email,
  AvatarWrapper,
  Image,
  MenuItemWrapper,
  RightSideNavigation,
  AuthButton,
  MenuWrapper,
  ProfileMainWrapper,
  ImageWrapper,
  ProfileInfoWrapper,
  MenuDesktopInfoWrapper,
  MenuItemDesktopNav,
  StyledNavLinkHeader,
} from './styles';
import {
  Box,
  IconButton,
  Typography,
  Menu,
  Container,
  Toolbar,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';
import LanguageChanger from './LanguageChanger';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavMenu } from '../../../static';
import NavMenu from './NavMenu';
import {
  useMyBusinessProfileSideBarQuery,
  useMyProfileSideBarQuery,
} from '../../../rq/hooks/useQueries';
import { NotificationsPopover } from '../../../components/NotificationsPopover/NotificationsPopover';

export default function Navbar() {
  const { isAuthenticated, user, logout, role } = useContext(AuthContext);

  const [navMenu, newNavMenu] = useNavMenu();
  const { data: businessData } = useMyBusinessProfileSideBarQuery({
    enabled: !!user?.businessId,
  });
  const { data: candidateData } = useMyProfileSideBarQuery({
    enabled: !!user?.candidateId,
  });
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const [arrow, setArrow] = useState<boolean>(false);

  const [anchorElNavMenuDesktop, setAnchorElNavMenuDesktop] =
    useState<null | HTMLElement>(null);

  const handleOpenNavDesktopMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNavMenuDesktop(event.currentTarget);
    setArrow(true);
  };

  const handleCloseNavDesktopMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNavMenuDesktop(null);
    setArrow(false);
  };
  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const { t } = useTranslation();
  const location = useLocation();
  const homePage = location.pathname === '/home';
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setSmall(window.pageYOffset > 150)
      );
    }
  }, []);

  return (
    <Container
      sx={{
        backgroundColor: Boolean(anchorElNav) ? '#72C2CC' : '',
        transition: 'background-color 0.5s',
      }}
    >
      <Toolbar disableGutters>
        {/* Desktop View Logo */}
        <Box
          sx={{
            flexGrow: 0,
            display: { xs: 'none', md: 'flex' },
            mr: 1,
            cursor: 'pointer',
          }}
          onClick={() => navigate('/home')}
        >
          <LogoIcon src={!homePage || small ? icon_black : icon} />
        </Box>

        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
            flexGrow: 1,
            cursor: 'pointer',
          }}
          onClick={() => navigate('/home')}
        >
          <LogoIcon src={!homePage || small ? icon_black : icon} />
        </Box>

        {/* Menu Mobile */}
        {!isAuthenticated && (
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: 'flex', md: 'none' },
            }}
          >
            {Boolean(anchorElNav) ? (
              <IconButton size='large' onClick={handleCloseNavMenu}>
                <ClearIcon sx={{ color: 'white' }} />
              </IconButton>
            ) : (
              <IconButton size='large' onClick={handleOpenNavMenu}>
                <SortIcon
                  sx={{
                    color: !homePage || small ? 'black' : 'white',
                    transform: 'rotate(180deg)',
                  }}
                />
              </IconButton>
            )}
            <MenuWrapper open={Boolean(anchorElNav)}>
              {newNavMenu.map((nav: any) => (
                <MenuItemWrapper
                  key={nav.id}
                  onClick={() => {
                    navigate(`${nav.to}`);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {nav.title}
                  </Typography>
                </MenuItemWrapper>
              ))}
              <LanguageChanger mobile={Boolean(anchorElNav)} />
            </MenuWrapper>
          </Box>
        )}
        {/* Menu Desktop */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          {navMenu.map((page: any) => (
            <StyledNavLinkHeader key={page.id} to={page.to}>
              {page.title}
            </StyledNavLinkHeader>
          ))}
        </Box>
        {/* Menu Mobile and Auth */}
        {user ? (
          <>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'flex', md: 'none' },
                alignItems: 'center',
              }}
            >
              {Boolean(anchorElNav) ? (
                <IconButton size='large' onClick={handleCloseNavMenu}>
                  <ClearIcon sx={{ color: 'white' }} />
                </IconButton>
              ) : (
                <>
                  <NotificationsPopover />
                  <IconButton size='large' onClick={handleOpenNavMenu}>
                    <SortIcon
                      sx={{
                        color: !homePage || small ? 'black' : 'white',
                        transform: 'rotate(180deg)',
                      }}
                    />
                  </IconButton>
                </>
              )}
              <MenuWrapper
                open={Boolean(anchorElNav)}
                onClick={handleCloseNavMenu}
              >
                {user.candidateId ? (
                  <NavMenu data={candidateData} mobile={Boolean(anchorElNav)} />
                ) : (
                  <NavMenu data={businessData} mobile={Boolean(anchorElNav)} />
                )}
              </MenuWrapper>
            </Box>
            {/* Menu Desktop and Auth */}
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <LanguageChanger small={small} mobile={Boolean(anchorElNav)} />
              <NotificationsPopover />
              <ProfileMainWrapper onClick={handleOpenNavDesktopMenu}>
                <ImageWrapper>
                  {!user.profilePicture ? (
                    <AvatarWrapper isCandidate={!!user.candidateId}>
                      {user.candidateId ? (
                        <Person sx={{ color: 'white' }} />
                      ) : (
                        <BusinessRounded sx={{ color: 'white' }} />
                      )}
                    </AvatarWrapper>
                  ) : (
                    <Image
                      src={user.profilePicture}
                      isCandidate={!!user.candidateId}
                    />
                  )}
                </ImageWrapper>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <ProfileInfoWrapper>
                    <Name>
                      {user.candidateId
                        ? user.firstName + ' ' + user.lastName
                        : user.company}
                    </Name>
                  </ProfileInfoWrapper>
                </Box>
                {arrow ? <ArrowDropUp /> : <ArrowDropDown />}
              </ProfileMainWrapper>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                disableScrollLock
                anchorEl={anchorElNavMenuDesktop}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNavMenuDesktop)}
                onClose={handleCloseNavDesktopMenu}
              >
                <MenuItemWrapper>
                  <MenuDesktopInfoWrapper>
                    <ImageWrapper>
                      {!user.profilePicture ? (
                        <AvatarWrapper isCandidate={!!user.candidateId}>
                          {user.candidateId ? (
                            <Person sx={{ color: 'white' }} />
                          ) : (
                            <BusinessRounded sx={{ color: 'white' }} />
                          )}
                        </AvatarWrapper>
                      ) : (
                        <Image
                          src={user.profilePicture}
                          isCandidate={!!user.candidateId}
                        />
                      )}
                    </ImageWrapper>
                    <ProfileInfoWrapper>
                      <Name>
                        {user.candidateId
                          ? user.firstName + ' ' + user.lastName
                          : user.company}
                      </Name>
                      <Email>{user.email}</Email>
                    </ProfileInfoWrapper>
                  </MenuDesktopInfoWrapper>
                  <MenuItemDesktopNav
                    onClick={(e) => {
                      navigate(`/${role}/profile/overview`);
                      handleCloseNavDesktopMenu(e);
                    }}
                  >
                    {t('left_side_bar.my_profile')}
                  </MenuItemDesktopNav>
                  <MenuItemDesktopNav onClick={logout}>
                    {t('signout')}
                  </MenuItemDesktopNav>
                </MenuItemWrapper>
              </Menu>
            </Box>
          </>
        ) : (
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <RightSideNavigation>
              <LanguageChanger small={small} mobile={Boolean(anchorElNav)} />
              <AuthButton
                isLoginButton={true}
                to='/login'
                homePage={homePage}
                small={small}
              >
                {t('login')}
              </AuthButton>
              <AuthButton
                isLoginButton={false}
                to='/register'
                homePage={homePage}
                small={small}
              >
                {t('sign_up')}
              </AuthButton>
            </RightSideNavigation>
          </Box>
        )}
      </Toolbar>
    </Container>
  );
}
