import {
  AddAccomplishmentModel,
  AddCertificationModel,
  AddEducationModel,
  AddExperienceModel,
  EditAccomplishmentModel,
  EditProfileDetailsModel,
  AddMotivationLetterModel,
  AddVideoPresentationModel,
  EditBusinessProfileModel,
} from './../../interfaces/types/User';
import axios from '../../utils/axios';
import { AxiosError } from 'axios';

export type MyExpectedResponseType = {
  errors: {
    code: string;
    message: string;
  }[];
};

export type MyExpectedDateResponseType = {
  errors: {
    StartDate: string[];
  };
};

export type MyErrorDateResponseType = {
  errors: {
    EndMonth: string[];
    EndYear: string[];
  };
};

export const editProfileDetailsMutation = async (
  payload: EditProfileDetailsModel
) => {
  await axios.put('candidate/editMyProfile', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deactivateAccount = async () => {
  try {
    const { data } = await axios.put('candidate/deactivateMyAccount');
    return data.data;
  } catch (err) {
    throw err;
  }
};

export const addAccomplishmentMutation = async (
  payload: AddAccomplishmentModel
) => {
  try {
    const { data } = await axios.post(
      'candidate/addCandidateAccomplishment',
      payload
    );
    return data.data;
  } catch (err) {
    const error = err as AxiosError<MyErrorDateResponseType>;
    if (error.response?.data.errors.EndMonth)
      throw error.response?.data.errors.EndMonth[0];
    if (error.response?.data.errors.EndYear)
      throw error.response?.data.errors.EndYear[0];
  }
};

export const editAccomplishmentMutation = async (
  candidateAccomplishmentId: any,
  payload: EditAccomplishmentModel
) => {
  try {
    const { data } = await axios.put(
      `candidate/editCandidateAccomplishment/${candidateAccomplishmentId}`,
      payload
    );
    return data.data;
  } catch (err) {
    const error = err as AxiosError<MyErrorDateResponseType>;
    if (error.response?.data.errors.EndMonth)
      throw error.response?.data.errors.EndMonth[0];
    if (error.response?.data.errors.EndYear)
      throw error.response?.data.errors.EndYear[0];
  }
};

export const deleteCVMutation = async (fileId: any) => {
  const response = await axios.delete(`candidate/deleteCv/${fileId}`);

  return response.data.data;
};

export const deleteAccomplishmentMutation = async (
  candidateAccomplishmentId: any
) => {
  await axios.delete(
    `candidate/deleteAccomplishment/${candidateAccomplishmentId}`
  );
};

export const deleteCertificationMutation = async (
  candidateCertificationId: any
) => {
  await axios.delete(
    `candidate/deleteCertification/${candidateCertificationId}`
  );
};

export const deleteProfilePicture = async () => {
  await axios.delete('/candidate/deleteMyProfilePicture');
};
// Education
export const addEducationMutation = async (payload: AddEducationModel) => {
  try {
    const { data } = await axios.post('candidate/addEducation', payload);
    return data.data;
  } catch (err) {
    const error = err as AxiosError<MyErrorDateResponseType>;
    if (error.response?.data.errors.EndMonth)
      throw error.response?.data.errors.EndMonth[0];
    if (error.response?.data.errors.EndYear)
      throw error.response?.data.errors.EndYear[0];
  }
};

export const deleteEducationMutation = async (candidateEducationId: any) => {
  await axios.delete(`candidate/deleteEducation/${candidateEducationId}`);
};

export const editEducationMutation = async (
  candidateEducationId: any,
  payload: AddEducationModel
) => {
  try {
    const { data } = await axios.put(
      `candidate/editCandidateEducation/${candidateEducationId}`,
      payload
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MyErrorDateResponseType>;
    if (error.response?.data.errors.EndMonth)
      throw error.response?.data.errors.EndMonth[0];
    if (error.response?.data.errors.EndYear)
      throw error.response?.data.errors.EndYear[0];
  }
};
// Motivation Letter
export const addMotivationLetterMutation = async (
  data: AddMotivationLetterModel
) => {
  await axios.post('candidate/addMotivationLetter', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteLetterMotivationMutation = async (fileId: any) => {
  await axios.delete(`candidate/deleteMotivationLetter/${fileId}`);
};

// CV

export const addCVMutation = async (data: AddMotivationLetterModel) => {
  await axios.post('candidate/addCv', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
// Certification Mutation
export const addCertificationMutation = async (
  payload: AddCertificationModel
) => {
  try {
    const { data } = await axios.post(
      'candidate/addCandidateCertification',
      payload
    );
    return data.data;
  } catch (err) {
    const error = err as AxiosError<MyErrorDateResponseType>;
    if (error.response?.data.errors.EndMonth)
      throw error.response?.data.errors.EndMonth[0];
    if (error.response?.data.errors.EndYear)
      throw error.response?.data.errors.EndYear[0];
  }
};

export const editCertificationMutation = async (
  candidateCertificationId: any,
  payload: AddCertificationModel
) => {
  try {
    await axios.put(
      `candidate/editCandidateCertification/${candidateCertificationId}`,
      payload
    );
  } catch (err) {
    const error = err as AxiosError<MyErrorDateResponseType>;
    if (error.response?.data.errors.EndMonth)
      throw error.response?.data.errors.EndMonth[0];
    if (error.response?.data.errors.EndYear)
      throw error.response?.data.errors.EndYear[0];
  }
};

//Digital book
export const addDigitalBook = async (data: AddMotivationLetterModel) => {
  await axios.post('candidate/addDigitalBook', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteDigitalBook = async (fileId: any) => {
  await axios.delete(`candidate/deleteDigitalBook/${fileId}`);
};

// Experience
export const addExperienceMutation = async (data: AddExperienceModel) => {
  try {
    await axios.post('candidate/addWorkExperience', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    const error = err as AxiosError<MyErrorDateResponseType>;
    if (error.response?.data.errors.EndMonth)
      throw error.response?.data.errors.EndMonth[0];
    if (error.response?.data.errors.EndYear)
      throw error.response?.data.errors.EndYear[0];
  }
};

export const editExperienceMutation = async (
  workExperienceId: any,
  payload: AddExperienceModel
) => {
  try {
    const { data } = await axios.put(
      `candidate/editWorkExperience/${workExperienceId}`,
      payload
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MyErrorDateResponseType>;
    if (error.response?.data.errors.EndMonth)
      throw error.response?.data.errors.EndMonth[0];
    if (error.response?.data.errors.EndYear)
      throw error.response?.data.errors.EndYear[0];
  }
};

export const deleteLogoExperienceMutation = async (workExperienceId: any) => {
  await axios.delete(
    `candidate/deleteWorkExperienceCompanyLogo/${workExperienceId}`
  );
};

export const deleteExperienceMutation = async (workExperienceId: any) => {
  await axios.delete(`candidate/deleteWorkExperience/${workExperienceId}`);
};

// Add and Edit Profile Picture
export const addEditProfilePictureMutation = async (
  data: File | string | null
) => {
  await axios.post('candidate/addEditMyProfilePicture', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

// Video Presentation
export const addVideoPresentationMutation = async (
  data: AddVideoPresentationModel
) => {
  await axios.post('candidate/addVideo', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteVideoPresentationMutation = async (fileId: any) => {
  const response = await axios.delete(`candidate/deleteVideo/${fileId}`);

  return response.data.data;
};

// Delete Like Video
export const deleteLikeVideoMutation = async (likedUserId: any) => {
  try {
    const { data } = await axios.delete(`userLike/${likedUserId}`);
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

export const addVideoLikeMutation = async (likedUserId: any) => {
  try {
    const { data } = await axios.post(`userLike/${likedUserId}`);
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

// Edit Business Profile

export const editBusinessProfileDetailsMutation = async (
  payload: EditBusinessProfileModel
) => {
  await axios.put('business/profile', payload);
};

// DELETE BUSINESS PROFILE PICTURE

export const deleteBusinessProfilePicture = async () => {
  await axios.delete('business/profile/picture');
};

// ADD BUSINESS VIDEO PRESENTATION
export const addBusinessVideoPresentationMutation = async (
  data: AddVideoPresentationModel
) => {
  await axios.post('business/profile/video', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

// DELETE BUSINESS VIDEO PRESENTATION

export const deleteBusinessVideoPresentationMutation = async (fileId: any) => {
  const response = await axios.delete(`business/video/${fileId}`);

  return response.data.data;
};

// MAKE A USER FAVORITE

export const addUserToFavoritesVideoLikeMutation = async (
  favoriteUserId: any
) => {
  try {
    const { data } = await axios.post(`userFavorite/${favoriteUserId}`);
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteUserToFavoritesVideoLikeMutation = async (
  favoriteUserId: any
) => {
  try {
    const { data } = await axios.delete(`userFavorite/${favoriteUserId}`);
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

// UNLOCK STUDENT FIELD
export const unlockCandidateField = async (candidateId: any) => {
  try {
    const { data } = await axios.post(`wallet/${candidateId}`);
    return data.data;
  } catch (err) {
    throw err;
  }
};

// ADD VIDEO VIEW

export const addCandidateVideoView = async (candidateVideoId: any) => {
  try {
    const { data } = await axios.put(
      `candidate/addVideoView/${candidateVideoId}`
    );
    return data.data;
  } catch (err) {
    throw err;
  }
};

export const addBusinessVideoView = async (businessVideoId: any) => {
  try {
    const { data } = await axios.put(
      `business/addVideoView/${businessVideoId}`
    );
    return data.data;
  } catch (err) {
    throw err;
  }
};

export const packageSubscription = async (packageId: number) => {
  try {
    const { data } = await axios.post(`subscription/${packageId}`);
    return data.data;
  } catch (err) {
    console.log(err);
  }
};
