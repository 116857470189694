import {
  AddCertificationModel,
  AddCVModel,
  AddEducationModel,
  AddExperienceModel,
  AddMotivationLetterModel,
  EditBusinessProfileModel,
} from './../../interfaces/types/User';
import {
  addBusinessVideoPresentationMutation,
  addBusinessVideoView,
  addCandidateVideoView,
  addCertificationMutation,
  addDigitalBook,
  addEditProfilePictureMutation,
  addEducationMutation,
  addExperienceMutation,
  addMotivationLetterMutation,
  addUserToFavoritesVideoLikeMutation,
  addVideoLikeMutation,
  deactivateAccount,
  deleteBusinessProfilePicture,
  deleteBusinessVideoPresentationMutation,
  deleteDigitalBook,
  deleteLetterMotivationMutation,
  deleteLikeVideoMutation,
  deleteLogoExperienceMutation,
  deleteUserToFavoritesVideoLikeMutation,
  deleteVideoPresentationMutation,
  editAccomplishmentMutation,
  editBusinessProfileDetailsMutation,
  editCertificationMutation,
  editEducationMutation,
  editExperienceMutation,
  packageSubscription,
  unlockCandidateField,
} from './../mutations/EditProfile.mutation';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import {
  AddAccomplishmentModel,
  EditAccomplishmentModel,
  EditProfileDetailsModel,
  AddVideoPresentationModel,
} from '../../interfaces/types/User';
import {
  addAccomplishmentMutation,
  deleteCVMutation,
  editProfileDetailsMutation,
  addCVMutation,
  deleteAccomplishmentMutation,
  deleteProfilePicture,
  deleteCertificationMutation,
  addVideoPresentationMutation,
  deleteExperienceMutation,
  deleteEducationMutation,
} from '../mutations/EditProfile.mutation';

export const useEditProfileDetails = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(
    ['editProfileDetails'],
    (data: EditProfileDetailsModel) => editProfileDetailsMutation(data),
    options
  );
};

export const useDeactivateMyAccount = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(['deactivate-my-account'], deactivateAccount, options);
};

// Add and Edit Profile Picture
export const useAddEditProfilePicture = () => {
  return useMutation(['addEditProfilePicture'], (photo: File | string | null) =>
    addEditProfilePictureMutation(photo)
  );
};

export const useAddAccomplishment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['addAccomplishment'],
    (data: AddAccomplishmentModel) => addAccomplishmentMutation(data),
    { onSuccess: () => queryClient.fetchQuery(['profile']) }
  );
};

export const useDeleteCV = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, { fileId: number }, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, { fileId: number }, unknown> => {
  return useMutation(
    ['delete-cv'],
    ({ fileId }) => deleteCVMutation(fileId),
    options
  );
};

export const useAddCV = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, AddCVModel, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, AddCVModel, unknown> => {
  return useMutation(
    ['addCV'],
    (data: AddCVModel) => addCVMutation(data),
    options
  );
};

export const useDeleteAccomplishment = (
  options?: Omit<
    UseMutationOptions<any, unknown, void, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<any, unknown, void, unknown> =>
  useMutation(
    ['deleteAccomplishment'],
    (candidateAccomplishmentId: any) =>
      deleteAccomplishmentMutation(candidateAccomplishmentId),
    options
  );

export const useEditAccomplishment = (
  candidateAccomplishmentId: any
): UseMutationResult<unknown, unknown, EditAccomplishmentModel> => {
  const queryClient = useQueryClient();
  return useMutation(
    ['editAccomplishment'],
    (data: EditAccomplishmentModel) =>
      editAccomplishmentMutation(candidateAccomplishmentId, data),
    { onSuccess: () => queryClient.fetchQuery(['profile']) }
  );
};

export const useDeleteMyProfilePicture = (
  options?: Omit<UseMutationOptions<any>, 'mutationKey' | 'mutationFn'>
) => {
  return useMutation(
    ['deleteMyProfilePicture'],
    () => deleteProfilePicture(),
    options
  );
};
// Education
export const useAddEducation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['addEducation'],
    (data: AddEducationModel) => addEducationMutation(data),
    { onSuccess: () => queryClient.fetchQuery(['profile']) }
  );
};

export const useEditEducation = (
  candidateEducationId: any,
  options?: Omit<
    UseMutationOptions<unknown, unknown, AddEducationModel, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, AddEducationModel> => {
  return useMutation(
    ['editEducation'],
    (data: AddEducationModel) =>
      editEducationMutation(candidateEducationId, data),
    options
  );
};

export const useAddMotivationLetter = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, AddMotivationLetterModel, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, AddMotivationLetterModel> => {
  return useMutation(
    ['addMotivationLetter'],
    (data: AddMotivationLetterModel) => addMotivationLetterMutation(data),
    options
  );
};

export const useDeleteMotivationLetter = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, { fileId: number }, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, { fileId: number }, unknown> => {
  return useMutation(
    ['deleteMotivationLetter'],
    ({ fileId }) => deleteLetterMotivationMutation(fileId),
    options
  );
};

export const useDeleteDigitalBookMutation = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, { fileId: number }, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, { fileId: number }, unknown> => {
  return useMutation(
    ['delete-digital-book'],
    ({ fileId }) => deleteDigitalBook(fileId),
    options
  );
};

//Digial book
export const useAddDigitalBookMutation = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, AddMotivationLetterModel, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, AddMotivationLetterModel> => {
  return useMutation(
    ['add-digital-book'],
    (data: AddMotivationLetterModel) => addDigitalBook(data),
    options
  );
};

// Certification Mutations
export const useAddCertification = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['addCertificationLetter'],
    (data: AddCertificationModel) => addCertificationMutation(data),
    { onSuccess: () => queryClient.fetchQuery(['profile']) }
  );
};

export const useEditCertification = (
  candidateCertificationId: any
): UseMutationResult<unknown, unknown, AddCertificationModel> => {
  const queryClient = useQueryClient();
  return useMutation(
    ['editCertification'],
    (data: AddCertificationModel) =>
      editCertificationMutation(candidateCertificationId, data),
    { onSuccess: () => queryClient.fetchQuery(['profile']) }
  );
};

export const useDeleteCertification = (
  options?: Omit<
    UseMutationOptions<any, unknown, void, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<any, unknown, void, unknown> =>
  useMutation(
    ['deleteCertification'],
    (candidateCertificationId: any) =>
      deleteCertificationMutation(candidateCertificationId),
    options
  );

export const useDeleteEducation = (
  options?: Omit<
    UseMutationOptions<any, unknown, void, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<any, unknown, void, unknown> =>
  useMutation(
    ['deleteEducation'],
    (candidateEducationId: any) =>
      deleteEducationMutation(candidateEducationId),
    options
  );

// Experiences

export const useAddExperience = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['addExperience'],
    (data: AddExperienceModel) => addExperienceMutation(data),
    { onSuccess: () => queryClient.fetchQuery(['profile']) }
  );
};

export const useEditExperience = (
  workExperienceId: any,
  options?: Omit<
    UseMutationOptions<unknown, unknown, AddExperienceModel, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, AddExperienceModel> =>
  useMutation(
    ['editExperience'],
    (data: AddExperienceModel) =>
      editExperienceMutation(workExperienceId, data),
    options
  );

export const useDeleteWorkExperience = (
  options?: Omit<
    UseMutationOptions<any, unknown, void, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<any, unknown, void, unknown> =>
  useMutation(
    ['delete-work-experienece'],
    (workExperienceId: any) => deleteExperienceMutation(workExperienceId),
    options
  );

export const useDeleteWorkExperienceLogo = (): UseMutationResult<
  unknown,
  unknown,
  { workExperienceId: number },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ['delete-work-experience-logo'],
    ({ workExperienceId }) => deleteLogoExperienceMutation(workExperienceId),
    { onSuccess: () => queryClient.fetchQuery(['profile']) }
  );
};

// Video Presentation

export const useAddVideoPresenatition = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['addVideo'],
    (data: AddVideoPresentationModel) => addVideoPresentationMutation(data),
    {
      onSuccess: () => queryClient.fetchQuery(['profile']),
    }
  );
};

export const useDeleteVideoPresentation = (): UseMutationResult<
  unknown,
  unknown,
  { fileId: number },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ['delete-video-presentation'],
    ({ fileId }) => deleteVideoPresentationMutation(fileId),
    { onSuccess: () => queryClient.fetchQuery(['profile']) }
  );
};

// Delete Liked Video
export const useDeleteLikedVideo = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, { likedUserId: number }, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, { likedUserId: number }, unknown> => {
  return useMutation(
    ['delete-liked-video'],
    ({ likedUserId }) => deleteLikeVideoMutation(likedUserId),
    options
  );
};

// Add Video Like

export const useAddVideoLike = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, { likedUserId: number }, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, { likedUserId: number }, unknown> => {
  return useMutation(
    ['add-video-like'],
    ({ likedUserId }) => addVideoLikeMutation(likedUserId),
    options
  );
};

// EDIT BUSINESS PROFILE

export const useEditBusinessProfileDetails = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(
    ['editBusinessProfileDetails'],
    (data: EditBusinessProfileModel) =>
      editBusinessProfileDetailsMutation(data),
    options
  );
};

// DELETE BUSINESS PROFILE PICTURE
export const useDeleteBusinessProfilePicture = (
  options?: Omit<UseMutationOptions<any>, 'mutationKey' | 'mutationFn'>
) => {
  return useMutation(
    ['deleteMyBusinessProfilePicture'],
    () => deleteBusinessProfilePicture(),
    options
  );
};

// ADD BUSINESS VIDEO PRESENTATION

export const useAddBusinessVideoPresenatition = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['addBusinessVideo'],
    (data: AddVideoPresentationModel) =>
      addBusinessVideoPresentationMutation(data),
    {
      onSuccess: () => queryClient.fetchQuery(['profile']),
    }
  );
};

// DELETE BUSINESS VIDEO

export const useDeleteBusinessVideoPresentation = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, { fileId: number }, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, { fileId: number }, unknown> => {
  return useMutation(
    ['delete-video-presentation'],
    ({ fileId }) => deleteBusinessVideoPresentationMutation(fileId),
    options
  );
};

export const useAddUserToFavorites = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, { favoriteUserId: number }, unknown> => {
  return useMutation(
    ['add-user-to-favorite'],
    ({ favoriteUserId }) => addUserToFavoritesVideoLikeMutation(favoriteUserId),
    options
  );
};

export const useDeleteUserToFavorites = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, { favoriteUserId: number }, unknown> => {
  return useMutation(
    ['delete-user-to-favorite'],
    ({ favoriteUserId }) =>
      deleteUserToFavoritesVideoLikeMutation(favoriteUserId),
    options
  );
};
export const useUnlockCandidateFields = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, { candidateId: string }, unknown> => {
  return useMutation(
    ['unlock-candidate-field'],
    ({ candidateId }) => unlockCandidateField(candidateId),
    options
  );
};

// ADD VIDEO VIEWS

export const useAddCandidateVideoView = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, { candidateVideoId: number }, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(
    ['add-candidate-video-view'],
    ({ candidateVideoId }) => addCandidateVideoView(candidateVideoId),
    options
  );
};

export const useAddBusinessVideoView = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, { businessVideoId: number }, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(
    ['add-business-video-view'],
    ({ businessVideoId }) => addBusinessVideoView(businessVideoId),
    options
  );
};

export const usePackageSubscriptionMutation = (
  packageId: number,
  options?: Omit<
    UseMutationOptions<unknown, unknown, { packageId: number }, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(
    [`package-subscription-${packageId}`],
    () => packageSubscription(packageId),
    options
  );
};
