import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTemplate from '../../../components/PageTemplate/PageTemplate';
import PostJob from '../../../modals/components/layouts/business-dashboard/JobOffers/PostJob';

export default function PostJobPage() {
  const { t } = useTranslation();
  return (
    <Grid item md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <PageTemplate title={t('post_a_job')} content={<PostJob />} />
    </Grid>
  );
}
