import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTemplate from '../../../components/PageTemplate/PageTemplate';
import AppliedJobs from '../../../modals/components/layouts/student-dashboard/AppliedJobs/AppliedJobs';

export default function AppliedJobsPage() {
  const { t } = useTranslation();
  return (
    <Grid item lg={6} md={9} xs={12} sx={{ display: { xs: 'block' } }}>
      <PageTemplate title={t('applied_jobs')} content={<AppliedJobs />} />
    </Grid>
  );
}
