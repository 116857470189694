import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StateType } from '../../../../interfaces/JobOffers.model';
import { useFormContext } from 'react-hook-form';
import { UserInputs } from '../../../../interfaces/types/User';

const TypeOfEmployment = () => {
  const { t } = useTranslation();
  const {
    setValue,
    formState: {
      errors: { employementTypeIds },
    },
  } = useFormContext<UserInputs>();

  const [checkboxValue, setCheckboxValue] = useState({
    cdd: false,
    internship: false,
    jobStudent: false,
  });
  const typeOfEmployments = [
    { id: 1, name: 'cdd', label: t('cdd') },
    { id: 2, name: 'internship', label: t('internship') },
    { id: 3, name: 'jobStudent', label: t('job_student') },
  ];

  /**handlers */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setCheckboxValue((prevState) => {
      const newState = {
        ...prevState,
        [name as keyof typeof prevState]: checked, // Ensure name is treated as a valid key
      };

      // Compute selected ids after state is updated
      const selectedIds = Object.keys(newState)
        .filter((key) => newState[key as keyof typeof newState])
        .map((key) => typeOfEmployments.find((item) => item.name === key)?.id)
        .filter((id): id is number => id !== undefined);

      setValue('employementTypeIds', selectedIds);

      return newState;
    });
  };
  return (
    <div style={{ marginBottom: '.5rem' }}>
      <Typography component='p' className='font-montserrat font-bold mb-2'>
        {t('type_of_employment')}
      </Typography>
      <FormGroup row>
        {typeOfEmployments.map(({ id, label, name }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxValue[name as keyof StateType]}
                onChange={handleChange}
                name={name}
                value={id}
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
      {!!employementTypeIds && (
        <Typography sx={{ color: 'red' }} variant='caption' display='block'>
          {employementTypeIds?.message}
        </Typography>
      )}
    </div>
  );
};

export default TypeOfEmployment;
