import { Box, IconButton } from "@mui/material";
import { ChangeEvent, useState } from "react";
import {
  NoFileWrapper,
  UploadIcon,
  FileWrapper,
  FileName,
  DiscardButton,
  ButtonsWrapper,
  VideoInfoWrapper,
  PreviewFile,
} from "../../styles";
import cv_icon from "../../../../../../../assets/icons/cv-icon.png";
import { useForm } from "react-hook-form";
import { useAddCV } from "../../../../../../../rq/hooks/useEditProfile.mutation";
import { AddCVModel } from "../../../../../../../interfaces/types/User";
import { SaveChangesButton } from "../../ProfileDetails/EditProfileDetails/styles";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import PdfViewer from "../../../../../../../components/PdfViewer/PdfViewer";

export default function UploadCV({ cv }: any) {
  const methods = useForm<AddCVModel>();
  const { handleSubmit, watch, setValue } = methods;
  // react mutation
  const { mutate: addCV, isLoading } = useAddCV({
    onSuccess: (res) => {
      toast.success(t("successMessage.admin_verification"));
      setValue("file", "");
    },
  });

  // react mutation

  const onSubmit = async (data: AddCVModel) => {
    const formData: any = new FormData();
    formData.append("file", data.file, "file.pdf");
    addCV(formData);
  };
  const fileUploaded: any = watch("file");
  const { t } = useTranslation();

  const [state, setState] = useState(cv?.filePath);
  const handleFileSelect = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files && files.length !== 0) {
      setState(files[0].name);
      setValue("file", files[0]);
    }
  };
  const handleDiscard = () => {
    setValue("file", "");
    setState(cv.filePath);
  };
  const regex = /[^\/]+$/;

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <NoFileWrapper>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <VideoInfoWrapper>
            <FileWrapper>
              <UploadIcon src={cv_icon} />
              <FileName>{state?.match(regex)}</FileName>
              <PreviewFile onClick={() => setOpen(true)}>
                ({t("preview")})
              </PreviewFile>
            </FileWrapper>
            {!fileUploaded && (
              <IconButton component="label" sx={{ marginBottom: "0.5rem" }}>
                <input
                  id="cv"
                  hidden
                  accept="application/pdf, image/jpeg, image/png"
                  type="file"
                  name="file"
                  onChange={handleFileSelect}
                />
                <EditIcon />
              </IconButton>
            )}
          </VideoInfoWrapper>

          {fileUploaded && (
            <ButtonsWrapper>
              <DiscardButton onClick={handleDiscard} variant="contained">
                {t("discard")}
              </DiscardButton>
              <SaveChangesButton
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                {t("save_changes")}
              </SaveChangesButton>
            </ButtonsWrapper>
          )}
        </Box>
      </NoFileWrapper>
      <PdfViewer name="CV Preview" open={open} setOpen={setOpen} file={state} />
    </>
  );
}
