import { BackButtonContainer, CoverContainer } from './styles';
import { Avatar, Button, Container, Typography } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import ApplyForJobModal from './ApplyForJobModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetJobAdDetailsQuery } from '../../../../../rq/hooks/useJob.mutation';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';
import { BusinessOutlined } from '@mui/icons-material';
import { AuthContext } from '../../../../../lib/context/AuthContext';
import toast from 'react-hot-toast';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const JobOffer = () => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [openWarningApplyJobModal, setOpenWarningApplyJobModal] =
    useState(false);
  const { data: jobDetails, isLoading: jobDetailsLoading } =
    useGetJobAdDetailsQuery(Number(id), {
      onError: (err: any) => {
        if (err.response.data.errors[0].code === 'job_is_blocked') {
          navigate(-1);
          toast.error(t('job_is_blocked'));
        }
      },
    });

  const descriptions = [
    {
      id: 1,
      name: t('enterprise_description'),
      data: jobDetails?.enterpriseDescription,
    },
    {
      id: 2,
      name: t('job_description_title'),
      data: jobDetails?.jobDescription,
    },
    {
      id: 3,
      name: t('function_title'),
      data: jobDetails?.functionDescription,
    },
    {
      id: 4,
      name: t('offer_title'),
      data: jobDetails?.offerDescription,
    },
    {
      id: 5,
      name: t('teaser_title'),
      data: jobDetails?.teaser,
    },
  ];

  if (jobDetailsLoading) return <CircularIndeterminate />;
  return (
    <>
      <div className='bg-[#3f6fe80d]'>
        <Container sx={{ pt: '70px' }}>
          <div
            className=' top-3 flex items-center cursor-pointer'
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosRoundedIcon
              className='fill-[#717275]'
              fontSize='small'
            />
            <Typography className='text-[#717275]'>{t('return')}</Typography>
          </div>
        </Container>
        <CoverContainer>
          <div className='flex flex-col justify-center items-center gap-2'>
            {jobDetails?.businessLogoPath ? (
              <Avatar src={jobDetails?.businessLogoPath} variant='rounded' />
            ) : (
              <Avatar variant='rounded'>
                <BusinessOutlined />
              </Avatar>
            )}

            <Typography className='font-bold text-3xl'>
              {jobDetails?.title}
            </Typography>
            <Typography className='font-bold text-xl'>
              {jobDetails?.businessName}
            </Typography>
            <div className='flex'>
              <FmdGoodOutlinedIcon className='fill-[#3F6FE8]' />
              <Typography>{jobDetails?.businessAddress}</Typography>
            </div>
            {user?.candidateId && jobDetails?.canApply && (
              <Button
                startIcon={<BusinessCenterIcon />}
                variant='contained'
                className='capitalize bg-[#3F6FE8]'
                onClick={() => setOpenWarningApplyJobModal(true)}
              >
                {t('apply_for_this_job')}
              </Button>
            )}
          </div>
        </CoverContainer>
      </div>

      <Container className='flex flex-col gap-6'>
        {descriptions.map(({ data, name }) => (
          <div className='flex flex-col gap-2'>
            {data && data !== 'undefined' && (
              <>
                <Typography className='font-bold text-base'>{name}</Typography>
                <Typography className='text-sm'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeURIComponent(data),
                    }}
                  />
                </Typography>
              </>
            )}
          </div>
        ))}
        {user?.candidateId && jobDetails?.canApply && (
          <Button
            startIcon={<BusinessCenterIcon />}
            variant='outlined'
            className='capitalize w-fit'
            onClick={() => setOpenWarningApplyJobModal(true)}
          >
            {t('apply_for_this_job')}
          </Button>
        )}
      </Container>
      <ApplyForJobModal
        open={openWarningApplyJobModal}
        handleClose={() => setOpenWarningApplyJobModal(false)}
      />
    </>
  );
};

export default JobOffer;
