import { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../../../hooks/useDebounce';
import SearchVideos from '../../../../../components/SearchVideos/SearchVideos';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';
import {
  FetchDiv,
  NoCard,
  TypoFetch,
} from '../../../../../components/Card/styles';
import CardSkeleton from '../../../../../components/CardSkeleton/CardSkeleton';
import AppliedJob from './AppliedJob';
import { useGetAppliedJobsQuery } from '../../../../../rq/hooks/useJob.mutation';

export default function AppliedJobs() {
  const [search, setSearch] = useState<any>('');
  const [results, setResults] = useState<number | undefined>(0);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const {
    status,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useGetAppliedJobsQuery(debouncedSearchTerm, {
    getNextPageParam: (lastPage: any, pages) => {
      if (lastPage.metaData.pageNumber < lastPage.metaData.pageCount) {
        return lastPage.metaData.pageNumber + 1;
      }
    },
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (debouncedSearchTerm) {
      setResults(data?.pages[0].data.length);
    } else {
      setResults(0);
    }
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(
    () => {
      (async () => {
        if (debouncedSearchTerm) {
          setIsSearching(true);
          await refetch();
          setIsSearching(false);
        } else {
          await refetch();
          setIsSearching(false);
        }
      })();
    },
    [debouncedSearchTerm]
    // Only call effect if debounced search term changes
  );
  const { t } = useTranslation();
  return (
    <>
      <SearchVideos
        setSearch={setSearch}
        results={results}
        isSearching={isSearching}
        search={search}
      />
      {status === 'loading' ? (
        <CircularIndeterminate />
      ) : (
        <>
          {data?.pages.map((card, i) => (
            <Fragment key={i}>
              {card?.data?.length > 0 ? (
                card?.data?.map((job, index) => <AppliedJob job={job} />)
              ) : (
                <NoCard>{t('card.no_cards')}</NoCard>
              )}
            </Fragment>
          ))}
          {data && data?.pages[0].data.length > 0 && (
            <FetchDiv ref={ref} onClick={() => fetchNextPage()}>
              {isFetchingNextPage ? (
                <CardSkeleton />
              ) : hasNextPage ? (
                <TypoFetch>{t('card.search.load_more')}</TypoFetch>
              ) : (
                <TypoFetch>{t('card.search.nothing_more')}</TypoFetch>
              )}
            </FetchDiv>
          )}
        </>
      )}
    </>
  );
}
