import { Button, Typography } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { FileName } from "./styles";
import { UserInputs } from "../../../../interfaces/types/User";
import { useTranslation } from "react-i18next";

export function UploadCV() {
  const {
    register,
    watch,
    formState: {
      errors: { cv },
    },
  } = useFormContext<UserInputs>();
  const file: any = watch("cv")?.[0];
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: ".5rem" }}>
      <Typography component="p" className="font-montserrat font-bold mb-2">
        CV
      </Typography>
      <Typography variant="subtitle2" className="font-montserrat mb-2">
        {t("auth.sign_up.cv_in_pdf")}
      </Typography>
      <Button variant="outlined" component="label" startIcon={<FileUpload />}>
        {t("auth.sign_up.upload_button")}
        <input
          id="cv"
          hidden
          accept="application/pdf, image/jpeg, image/png"
          type="file"
          {...register("cv")}
        />
      </Button>
      {cv ? (
        <Typography sx={{ color: "red" }} variant="caption" display="block">
          <>{cv?.message}</>
        </Typography>
      ) : file ? (
        <FileName>{file.name}</FileName>
      ) : (
        ""
      )}
    </div>
  );
}
