import {
  LikedVideosModel,
  PackagePlan,
  SideBarBusinessModel,
} from './../../interfaces/types/User';
import {
  getMyLikedVideos,
  getInterestedOnYou,
  getAllCards,
  getBusinessProfile,
  getSideBarBusinessProfile,
  getMyFavouriteUsers,
  getPackagesPlans,
  getCandidateProfileFromBusiness,
  getStripeIntent,
  getCandidateCards,
  getBusinessCards,
  getHistoryOfPayments,
  fetchNotifications,
  fetchMySubscription,
  downloadCandidateFiles,
} from './../queries/index';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { SideBarModel, UserModel } from '../../interfaces/types/User';
import {
  getStudyFieldsOfStudy,
  getUniversities,
  getLanguages,
  getProfessions,
  getLanguagesSkills,
  getBusinessTypes,
  getProfile,
  getSideBarProfile,
  getAllCountries,
  getAllSkills,
  getCvPreview,
  getAllAccomplishments,
} from '../queries';

// Sign Up Canidadate Queries

export const useUniversityQuery = () =>
  useQuery(['university'], getUniversities);

export const useFieldsOfStudyQuery = () =>
  useQuery(['fieldOfStudy'], getStudyFieldsOfStudy);

export const useLanguagesQuery = () => useQuery(['languages'], getLanguages);

export const useProfessionsQuery = () =>
  useQuery(['professions'], getProfessions);

export const useLanguageSkillQuery = () =>
  useQuery(['language-skills'], getLanguagesSkills);

//   Candidate Profile Queries

export const useMyProfileQuery = (
  options?: Omit<UseQueryOptions<UserModel>, 'queryKey' | 'queryFn'>
): UseQueryResult<UserModel> => useQuery(['profile'], getProfile, options);

// Side Bar
export const useMyProfileSideBarQuery = (
  options?: Omit<UseQueryOptions<SideBarModel>, 'queryKey' | 'queryFn'>
): UseQueryResult<SideBarModel> =>
  useQuery(['sideBarProfile'], getSideBarProfile, options);

export const useMyBusinessProfileQuery = (
  options?: Omit<UseQueryOptions<UserModel>, 'queryKey' | 'queryFn'>
): UseQueryResult<UserModel> =>
  useQuery(['businessProfile'], getBusinessProfile, options);

// Business Queries

export const useBusinessTypeQuery = () =>
  useQuery(['business-types'], getBusinessTypes);

// Get All Countries
export const useGetAllCountriesQuery = () =>
  useQuery(['get-all-countries'], getAllCountries);

// Get All Skills

export const useGetAllSkillsQuery = () =>
  useQuery(['get-all-skills'], getAllSkills);

// Get CV Preview
export const useGetCvPreviewQuery = (
  fileId: any,
  options?: Omit<UseQueryOptions<number>, 'queryKey' | 'queryFn'>
): UseQueryResult<number> =>
  useQuery([`cv-preview-${fileId}`], () => getCvPreview(fileId), options);

// Get Accomplishments

export const useGetAllAccomplishmentsQuery = () =>
  useQuery(['get-all-accomplishments'], getAllAccomplishments);

export const useGetMyLikedVideosQuery = (
  search: any,
  options?: Omit<
    UseInfiniteQueryOptions<
      unknown,
      unknown,
      { data: LikedVideosModel[] },
      unknown
    >,
    'queryKey' | 'queryFn'
  >
): UseInfiniteQueryResult<{ data: LikedVideosModel[] }, unknown> =>
  useInfiniteQuery(
    ['get-my-liked-video'],
    ({ pageParam = 1 }, queryKey = search) =>
      getMyLikedVideos(pageParam, queryKey),
    options
  );

export const useGetInterestedOnMeQuery = (
  options?: Omit<
    UseInfiniteQueryOptions<
      unknown,
      unknown,
      { data: LikedVideosModel[] },
      unknown
    >,
    'queryKey' | 'queryFn'
  >
): UseInfiniteQueryResult<{ data: LikedVideosModel[] }, unknown> =>
  useInfiniteQuery(
    ['get-my-interested-video'],
    ({ pageParam = 1 }) => getInterestedOnYou(pageParam),
    options
  );

// Feed Get All Cards
export const useGetAllCardsQuery = (
  search: any,
  options?: Omit<
    UseInfiniteQueryOptions<
      unknown,
      unknown,
      { data: LikedVideosModel[] },
      unknown
    >,
    'queryKey' | 'queryFn'
  >
): UseInfiniteQueryResult<{ data: LikedVideosModel[] }, unknown> =>
  useInfiniteQuery(
    ['get-all-cards'],
    ({ pageParam = 1 }, queryKey = search) => getAllCards(pageParam, queryKey),
    options
  );

// BUSINESS PROFILE SIDEBAR

export const useMyBusinessProfileSideBarQuery = (
  options?: Omit<UseQueryOptions<SideBarBusinessModel>, 'queryKey' | 'queryFn'>
): UseQueryResult<SideBarBusinessModel> =>
  useQuery(['sideBarBusinessProfile'], getSideBarBusinessProfile, options);

// GET MY FAVORITE USERS
export const useGetMyFavoriteUsersQuery = (
  search: any,
  options?: Omit<
    UseInfiniteQueryOptions<
      unknown,
      unknown,
      { data: LikedVideosModel[] },
      unknown
    >,
    'queryKey' | 'queryFn'
  >
): UseInfiniteQueryResult<{ data: LikedVideosModel[] }, unknown> =>
  useInfiniteQuery(
    ['get-my-favorite-users'],
    ({ pageParam = 1 }, queryKey = search) =>
      getMyFavouriteUsers(pageParam, queryKey),
    options
  );

// PACKAGE PLAN QUERY

export const usePackagePlansQuery = (
  options?: Omit<UseQueryOptions<PackagePlan[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<PackagePlan[]> =>
  useQuery(['packagePlane'], getPackagesPlans, options);

// GET PROFILE, WHEN BUSINESS WANTS TO SEE CANDIDATE'S PROFILE

export const useGetCandidateProfileFromBusinessQuery = (
  candidateId: any,
  options?: Omit<UseQueryOptions<UserModel>, 'queryKey' | 'queryFn'>
): UseQueryResult<UserModel> =>
  useQuery(
    ['candidate-profile'],
    () => getCandidateProfileFromBusiness(candidateId),
    options
  );

export const useStripeCheckoutQuery = (
  packageId: any,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>
): UseQueryResult<any> =>
  useQuery(
    [`candidate-profile-stripe-${packageId}`],
    () => getStripeIntent(packageId),
    options
  );

export const useGetAllCandidateCardsQuery = (
  pageNumber: any,
  candidateId?: any,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>
): UseQueryResult<any> =>
  useQuery(
    ['get-all-candidate-cards', pageNumber],
    () => getCandidateCards(pageNumber, null, candidateId),
    options
  );

export const useGetAllCandidatesCardsInfiniteQuery = (
  search: any,
  options?: Omit<
    UseInfiniteQueryOptions<
      unknown,
      unknown,
      { data: LikedVideosModel[] },
      unknown
    >,
    'queryKey' | 'queryFn'
  >,
  candidateId?: any,
  professionId?: number[],
  fieldOfStudyId?: number,
  educationId?: number
): UseInfiniteQueryResult<{ data: LikedVideosModel[] }, unknown> => {
  return useInfiniteQuery(
    ['get-candidate-infinite-videos'],
    ({ pageParam = 1 }, queryKey = search) =>
      getCandidateCards(
        pageParam,
        queryKey,
        candidateId,
        professionId,
        fieldOfStudyId,
        educationId
      ),
    options
  );
};

export const useGetAllBusinessCardsQuery = (
  pageNumber: any,
  search?: any,
  businessId?: any,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>
): UseQueryResult<any> =>
  useQuery(
    ['get-all-business-cards', pageNumber],
    () => getBusinessCards(pageNumber, search, businessId),
    options
  );

// Infite Query for businesses

export const useGetAllBusinessCardsInfiniteQuery = (
  search: any,
  options?: Omit<
    UseInfiniteQueryOptions<
      unknown,
      unknown,
      { data: LikedVideosModel[] },
      unknown
    >,
    'queryKey' | 'queryFn'
  >
): UseInfiniteQueryResult<{ data: LikedVideosModel[] }, unknown> =>
  useInfiniteQuery(
    ['get-business-infinite-videos'],
    ({ pageParam = 1 }, queryKey = search) =>
      getBusinessCards(pageParam, queryKey, null),
    options
  );

export const useGetHistoryOfPaymentQuery = (
  pageNumber: any,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>
): UseQueryResult<any> =>
  useQuery(
    ['get-history-of-payments', pageNumber],
    () => getHistoryOfPayments(pageNumber),
    options
  );

//Notifications

export const useFetchNotificationQuery = () =>
  useQuery(['notifications'], fetchNotifications);

export const useFetchMySubscriptionQuery = () =>
  useQuery(['my-subscription'], fetchMySubscription);

export const useDownloadCandidateFilesQuery = (
  id: number,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>
): UseQueryResult<any> =>
  useQuery(
    [`candidate-files-${id}`],
    () => downloadCandidateFiles(id),
    options
  );
