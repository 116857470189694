import { Box, Button } from "@mui/material";
import {
  NoFileWrapper,
  NoFileTitle,
  UploadIcon,
  TypesOfFile,
  NoFileUploadButtonWrapper,
  NoFile,
  FileWrapper,
  FileName,
  DiscardButton,
  ButtonsWrapper,
} from "../../styles";
import upload_icon from "../../../../../../../assets/icons/upload_icon.png";
import cv_icon from "../../../../../../../assets/icons/cv-icon.png";
import { useForm } from "react-hook-form";
import { useAddMotivationLetter } from "../../../../../../../rq/hooks/useEditProfile.mutation";
import { AddMotivationLetterModel } from "../../../../../../../interfaces/types/User";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { SaveChangesButton } from "../../ProfileDetails/EditProfileDetails/styles";

export default function UploadMotivationLetter() {
  const methods = useForm<AddMotivationLetterModel>();
  const { register, handleSubmit, watch, setValue } = methods;
  const { t } = useTranslation();

  const { mutate: addMotivationLetter, isLoading } = useAddMotivationLetter({
    onSuccess: () => {
      toast.success(t("successMessage.admin_verification"));
    },
  });

  const onSubmit = async (data: AddMotivationLetterModel) => {
    const formData: any = new FormData();
    formData.append("file", data.file?.[0], "file.png");
    addMotivationLetter(formData);
  };
  const file: any = watch("file")?.[0];
  return (
    <>
      <NoFileWrapper>
        <NoFileTitle>{t("more_info.upload_motivation_letter")}</NoFileTitle>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          {file ? (
            <>
              <FileWrapper>
                <UploadIcon src={cv_icon} />
                <FileName>{file.name}</FileName>
              </FileWrapper>
              <ButtonsWrapper>
                <DiscardButton
                  variant="contained"
                  onClick={() => setValue("file", "")}
                >
                  {t("discard")}
                </DiscardButton>
                <SaveChangesButton
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                >
                  {t("save_changes")}
                </SaveChangesButton>
              </ButtonsWrapper>
            </>
          ) : (
            <NoFile>
              <Button component="label" sx={{ marginBottom: "0.5rem" }}>
                <NoFileUploadButtonWrapper>
                  <input
                    id="cv"
                    hidden
                    accept="application/pdf, image/jpeg, image/png"
                    type="file"
                    {...register("file")}
                  />
                  <UploadIcon src={upload_icon} />
                  <TypesOfFile>PDF, WORD, PNG, JPG, TXT</TypesOfFile>
                </NoFileUploadButtonWrapper>
              </Button>
            </NoFile>
          )}
        </Box>
      </NoFileWrapper>
    </>
  );
}
