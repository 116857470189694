import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import JobOffer from './JobOffer';
import { useGetBusinessJobAdsQuery } from '../../../../../rq/hooks/useJob.mutation';
import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';

const JobOffers = () => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: jobs = [], isLoading: jobsLoading } =
    useGetBusinessJobAdsQuery();

  if (jobsLoading) return <CircularIndeterminate />;
  return (
    <div className='flex flex-col gap-2'>
      <Button
        onClick={() => navigate('/Business/profile/post-job')}
        variant='contained'
        className='w-full rounded-full text-center bg-[#487BD9] p-2 text-white capitalize'
      >
        {t('create_a_new_job_offer')}
      </Button>
      <div>
        {jobs.map((job) => (
          <JobOffer job={job} />
        ))}
      </div>
    </div>
  );
};

export default JobOffers;
