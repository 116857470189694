import React, { FC } from 'react';
import { BootstrapDialog } from '../../../../../components/BootstrapDialog/BootstrapDialog';
import { Button, DialogContent, DialogProps, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { useDeleteJobOfferMutation } from '../../../../../rq/hooks/useJob.mutation';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

interface PostJobWarningProps extends DialogProps {
  handleClose: () => void;
  id: string;
}

const DeleteJobAd: FC<PostJobWarningProps> = ({ open, handleClose, id }) => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: deleteJobAd, isLoading: deleteJobOfferLoading } =
    useDeleteJobOfferMutation(Number(id), {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-business-job-ads']);
        handleClose();
        toast.success(t('delete_successfully'));
      },
    });
  // const { mutate: removeAdFromDraft, isLoading: removeAdFromDraftLoading } =
  //   useRemoveAdFromDraftMutation(Number(id), {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(['get-business-job-ads']);
  //       handleClose();
  //       toast.success(t('delete_successfully'));
  //     },
  //   });

  /**
   * hanlders
   */
  const handleCloseModal = () => handleClose();
  const handleDeleteJobOffer = () => {
    deleteJobAd(Number(id));
  };
  return (
    <>
      <BootstrapDialog
        aria-labelledby='customized-dialog-title'
        open={open}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            width: '100%',
          },
        }}
      >
        <DialogContent className='flex flex-col justify-center items-center'>
          <ErrorOutlineOutlinedIcon className='fill-[#FDC01C] text-7xl' />
          <Typography className='text-base'>{t('delete_warning')}</Typography>
          <div className='flex gap-1 mt-2'>
            <Button
              variant='contained'
              className='capitalize text-black'
              onClick={handleCloseModal}
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              variant='contained'
              className='bg-[#72C2CC] hover:bg-[#72C2CC] capitalize text-sm'
              loading={deleteJobOfferLoading}
              onClick={handleDeleteJobOffer}
            >
              {t('yes')}
            </LoadingButton>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default DeleteJobAd;
