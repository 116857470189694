import { Container, Stack, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Description,
  Link,
  PackagesWrapper,
  Title,
  TitleWrapper,
} from './styles';
import { usePackagePlansQuery } from '../../../../rq/hooks/useQueries';
import CircularIndeterminate from '../../../../components/CircularIndeterminate/CircularIndeterminate';
import CreditPurchasePlan from './CreditPurchasePlan/CreditPurchasePlan';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../../lib/context/AuthContext';

export default function CreditsPurchase() {
  /**
   * hooks
   */
  const [checked, setChecked] = useState(false);
  const { user } = useContext(AuthContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const { t } = useTranslation();
  const { data: packages = [], isLoading } = usePackagePlansQuery();

  /**
   * constants
   */
  const subscriptionPackages = packages.filter(
    (pack) => pack.isSubscription === true
  );

  const creditsPackages = packages.filter(
    (pack) => pack.isSubscription === false
  );

  return (
    <>
      <Container>
        <TitleWrapper>
          <Title>
            <span style={{ color: '#A8E8F5' }}>{t('credits.credits')}</span>
            &nbsp;
            {t('credits.purchase')}
          </Title>
          <Description className='text-center max-w-3xl'>
            {t('credits.overview_packages')}
          </Description>
          <Stack
            direction='row'
            spacing={1}
            sx={{ alignItems: 'center', mb: 3 }}
          >
            <Typography>{t('buy_through_packs')}</Typography>
            <Switch checked={checked} onChange={handleChange} />
            <Typography>{t('buy_through_sub')}</Typography>
          </Stack>
          {checked && user?.userSubscription && (
            <Typography className='font-bold text-lg'>
              {t('already_have_subscription')}
            </Typography>
          )}
        </TitleWrapper>
        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          <PackagesWrapper>
            {checked &&
              !user?.userSubscription &&
              subscriptionPackages.map((plan: any) => (
                <CreditPurchasePlan
                  key={plan.id}
                  plan={plan}
                  identifier={plan.id}
                />
              ))}
            {!checked &&
              creditsPackages.map((plan: any) => (
                <CreditPurchasePlan
                  key={plan.id}
                  plan={plan}
                  identifier={plan.id}
                />
              ))}
          </PackagesWrapper>
        )}
        <Link to='/home'>{t('choose_later')}</Link>
      </Container>
    </>
  );
}
