import React, { useContext } from 'react';
import { AuthContext } from '../../../lib/context/AuthContext';
import {
  AvatarWrapper,
  Image,
  ImageWrapper,
  ItemCount,
  ItemLabel,
  LinkDataMobileMenu,
  LinksMobileMenu,
  MainInfoWrapperMobileMenu,
  Name,
  NavLinkWrapper,
  NoLinkItemMobileMenu,
  NoLinksDataMobileMenu,
  ProfileInfoMobileMenu,
  SignoutButton,
} from './styles';
import {
  Person,
  BusinessRounded,
  Favorite,
  Visibility,
  PushPinRounded,
} from '@mui/icons-material';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CreditsWrapper,
  CreditTitle,
  CreditValue,
  PositionWrapper,
  StyledNavLink,
} from '../../../components/LeftSideBar/styles';
import { useLinkList } from '../../../components/LeftSideBar/lists';
import LanguageChanger from './LanguageChanger';

export default function NavMenu({ data, mobile }: any) {
  const { role, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [linkList, businessLink, navList] = useLinkList();
  return (
    <MainInfoWrapperMobileMenu>
      {data && (
        <>
          <ProfileInfoMobileMenu>
            <ImageWrapper style={{ alignSelf: 'center' }}>
              {!data.profilePicture ? (
                <AvatarWrapper isCandidate={!!data.candidateId}>
                  {data.candidateId ? (
                    <Person sx={{ color: 'white' }} />
                  ) : (
                    <BusinessRounded sx={{ color: 'white' }} />
                  )}
                </AvatarWrapper>
              ) : (
                <Image
                  src={data.profilePicture}
                  isCandidate={!!data.candidateId}
                />
              )}
            </ImageWrapper>
            <Name>
              {data.candidateId
                ? data.firstName + ' ' + data.lastName
                : data.company}
            </Name>
            <PositionWrapper>
              {data?.candidateId ? data?.professions.join(', ') : data?.country}
            </PositionWrapper>
            {data.candidateId ? (
              <CustomButton
                onClick={() => navigate(`/${role}/profile/settings`)}
                isSavingButton={false}
                name={t('left_side_bar.update_cv')}
              />
            ) : (
              <CreditsWrapper>
                <CreditTitle>{t('left_side_bar.your_credits')}</CreditTitle>
                <CreditValue>{data?.credits} Cr.</CreditValue>
                <CustomButton
                  onClick={() => navigate(`/${role}/profile/packets`)}
                  isSavingButton={false}
                  name={t('left_side_bar.buy_credits')}
                />
              </CreditsWrapper>
            )}
          </ProfileInfoMobileMenu>
          <LinksMobileMenu>
            {data.candidateId && (
              <NoLinksDataMobileMenu>
                <NoLinkItemMobileMenu>
                  <Favorite
                    sx={{
                      fontSize: '12px',
                      color: '#9D9D9D',
                      mr: 0.5,
                    }}
                  />
                  <ItemCount> {data.likes}</ItemCount>
                  <ItemLabel>{t('left_side_bar.likes')}</ItemLabel>
                </NoLinkItemMobileMenu>
                <NoLinkItemMobileMenu>
                  <Visibility
                    sx={{
                      fontSize: '12px',
                      color: '#9D9D9D',
                      mr: 0.5,
                    }}
                  />
                  <ItemCount> {data.profileViews}</ItemCount>
                  <ItemLabel>{t('left_side_bar.views')}</ItemLabel>
                </NoLinkItemMobileMenu>
                <NoLinkItemMobileMenu>
                  <PushPinRounded
                    sx={{
                      fontSize: '12px',
                      color: '#9D9D9D',
                      mr: 0.5,
                    }}
                  />
                  <ItemCount> {data.saves}</ItemCount>
                  <ItemLabel>{t('left_side_bar.stars')}</ItemLabel>
                </NoLinkItemMobileMenu>
              </NoLinksDataMobileMenu>
            )}
            <LinkDataMobileMenu>
              {data.candidateId
                ? linkList.map((link, index) => (
                    <StyledNavLink to={link.href} key={index}>
                      {link.title}
                    </StyledNavLink>
                  ))
                : businessLink.map((link, index) => (
                    <StyledNavLink to={link.href} key={index}>
                      {link.title}
                    </StyledNavLink>
                  ))}
            </LinkDataMobileMenu>
          </LinksMobileMenu>
          <NavLinkWrapper>
            {navList.map((list, index) => (
              <StyledNavLink to={list.href} key={index}>
                {list.title}
              </StyledNavLink>
            ))}
          </NavLinkWrapper>
          <LanguageChanger mobile={mobile} />
          <div style={{ margin: '0 1rem' }}>
            <SignoutButton onClick={logout}>{t('signout')}</SignoutButton>
          </div>
        </>
      )}
    </MainInfoWrapperMobileMenu>
  );
}
