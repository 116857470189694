import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  AddJobOfferDto,
  JobAdDetailsModel,
  JobAdsModel,
} from '../../interfaces/JobOffers.model';
import {
  addJobOffer,
  applyForJob,
  cancelSubscription,
  deleteJobOffer,
  editJobDraft,
  getAppliedJobs,
  getBusinessJobs,
  getJobAdCandidates,
  getJobAdDetails,
  getJobAds,
  reactivateSubscription,
  removeAdFromDraft,
} from '../mutations/Jobs.mutations';

export const useAddJobOfferMutation = () => {
  return useMutation(['add-job-offer'], (payload: AddJobOfferDto) =>
    addJobOffer(payload)
  );
};

export const useDeleteJobOfferMutation = (
  id: number,
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(['delete-job-offer'], () => deleteJobOffer(id), options);
};

export const useRemoveAdFromDraftMutation = (
  id: number,
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(
    [`remove-ad-from-draft-${id}`],
    () => removeAdFromDraft(id),
    options
  );
};

export const useGetJobAdsQuery = (
  params: { employmentTypes: number[] },
  options?: Omit<UseQueryOptions<JobAdsModel[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<JobAdsModel[]> => {
  return useQuery([`get-job-ads-${params}`], () => getJobAds(params), options);
};

export const useGetBusinessJobAdsQuery = () => {
  return useQuery(['get-business-job-ads'], getBusinessJobs);
};

export const useGetJobAdDetailsQuery = (
  jobId: number,
  options?: Omit<UseQueryOptions<JobAdDetailsModel>, 'queryKey' | 'queryFn'>
): UseQueryResult<JobAdDetailsModel> => {
  return useQuery(
    [`get-job-ad-details-${jobId}`],
    () => getJobAdDetails(jobId),
    options
  );
};

export const useApplyForJobOfferMutation = (
  id: number,
  options?: Omit<
    UseMutationOptions<unknown, { message: string }, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, { message: string }, any> => {
  return useMutation(
    ['delete-job-offer'],
    (payload: { message: string }) => applyForJob(id, payload),
    options
  );
};

export const useGetAppliedJobsQuery = (
  searchWord: string,
  options?: Omit<
    UseInfiniteQueryOptions<unknown, unknown, { data: JobAdsModel[] }, unknown>,
    'queryKey' | 'queryFn'
  >
): UseInfiniteQueryResult<{ data: JobAdsModel[] }, unknown> =>
  useInfiniteQuery(
    [`get-applied-job-ad-${searchWord}`],
    ({ pageParam = 1 }, queryKey = searchWord) =>
      getAppliedJobs(pageParam, queryKey),
    options
  );

export const useGetBusinessJobAdCanidatesQuery = (jobId: number) => {
  return useQuery([`get-business-job-ads-candiadtes-${jobId}`], () =>
    getJobAdCandidates(jobId)
  );
};

export const useCancelSubscriptionMutation = (
  userSubId: number,
  options?: Omit<
    UseMutationOptions<unknown, any, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, any, any> => {
  return useMutation(
    [`cancel-subscription-${userSubId}`],
    () => cancelSubscription(userSubId),
    options
  );
};

export const useReactivateSubscriptionMutation = (
  userSubId: number,
  options?: Omit<
    UseMutationOptions<unknown, any, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, any, any> => {
  return useMutation(
    [`reactivate-subscription-${userSubId}`],
    () => reactivateSubscription(userSubId),
    options
  );
};

export const useEditJobDraftMutation = (
  id: number,
  options?: Omit<
    UseMutationOptions<unknown, unknown, any, unknown>,
    'mutationKey' | 'mutationFn'
  >
): UseMutationResult<unknown, unknown, any> => {
  return useMutation(
    [`edit-job-draft-${id}`],
    (payload: AddJobOfferDto) => editJobDraft(id, payload),
    options
  );
};
