import { useTranslation } from "react-i18next";
import CircularIndeterminate from "../../../../../../components/CircularIndeterminate/CircularIndeterminate";
import { useFetchMySubscriptionQuery } from "../../../../../../rq/hooks/useQueries";
import { MainWrapper, Title } from "../../../styles";
import { Button, DialogContent, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../../../../../components/BootstrapDialog/BootstrapDialog";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { LoadingButton } from "@mui/lab";
import {
  useCancelSubscriptionMutation,
  useReactivateSubscriptionMutation,
} from "../../../../../../rq/hooks/useJob.mutation";
import toast from "react-hot-toast";
import BillingHistory from "../BillingHistory/BillingHistory";

export default function SubscriptionInfos() {
  /**
   * hooks
   */
  const [openEndSubscriptionModal, setOpenEndSubscriptionModal] =
    useState(false);
  const [openReactivateSubscriptionModal, setOpenReactivateSubscriptionModal] =
    useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: mySubscription,
    isLoading,
    refetch,
  } = useFetchMySubscriptionQuery();

  const { mutate: cancelSub, isLoading: cancelSubLoading } =
    useCancelSubscriptionMutation(mySubscription?.userSubscriptionId, {
      onSuccess: () => {
        refetch();
        toast.success(t("end_sub_successfully"));
        handleClose();
      },
    });
  const { mutate: reactivateSub, isLoading: reactivateSubLoading } =
    useReactivateSubscriptionMutation(mySubscription?.userSubscriptionId, {
      onSuccess: () => {
        refetch();
        toast.success(t("reactivate_sub_successfully"));
        handleClose();
      },
    });

  /**
   * handlers
   */
  const handleClose = () => {
    setOpenEndSubscriptionModal(false);
    setOpenReactivateSubscriptionModal(false);
  };

  if (!mySubscription)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "full",
          height: "full",
        }}
      >
        <Typography className="text-lg">{t("no_subscription")}</Typography>
        <Button
          variant="contained"
          type="button"
          className="bg-[#6095f6]"
          onClick={() => navigate("/credits-purchase")}
        >
          {t("buy_credits")}
        </Button>
      </div>
    );

  return (
    <>
      <MainWrapper>
        <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
          <Title>{t("current_subscription")}</Title>
          <div className="flex gap-1">
            {mySubscription?.statusId === 1 && (
              <Button
                variant="outlined"
                color="error"
                className="text-[#E65D50] capitalize"
                onClick={() => setOpenEndSubscriptionModal(true)}
              >
                {t("end_subscription")}
              </Button>
            )}
            <Button
              variant="contained"
              className="capitalize bg-[#3F6FE8] hover:bg-[#3F6FE8]"
              onClick={() => navigate(`/credits-purchase`)}
            >
              {t("manage_credits")}
            </Button>
          </div>
        </div>
        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div className="border-2 border-solid rounded-xl border-[#EFEFEF] p-2">
              <div className="flex justify-between items-baseline">
                <div>
                  <Typography className="text-[#999A99] text-sm">
                    {t("monthly_plan")}
                  </Typography>
                  <Typography className="text-[#999A99] text-base">
                    €{mySubscription?.packagePrice}/
                    <span>{mySubscription?.packagePoints}Cr.</span>
                  </Typography>
                </div>
                {mySubscription?.statusId === 1 && (
                  <Typography className="text-[#50A076] text-sm bg-[#CDF1E0] rounded-xl  px-2 text-center">
                    {t("active")}
                  </Typography>
                )}
                {mySubscription?.statusId === 9 && (
                  <Typography className="text-[#9D9D9D] text-sm bg-[#EAEAEA] rounded-xl  px-2 text-center">
                    {t("inactive")}
                  </Typography>
                )}
              </div>
            </div>
            <div className="border-2 border-solid rounded-xl border-[#EFEFEF] p-2">
              <Typography className="text-[#999A99] text-sm">
                {mySubscription?.statusId === 9 ? t("ends_at") : t("renew_at")}
              </Typography>
              <Typography className="text-base font-bold">
                {dayjs(mySubscription?.renewalDate).format("MMM D, YYYY")}
              </Typography>
              {mySubscription?.statusId === 9 && (
                <div
                  className="flex justify-end"
                  onClick={() => setOpenReactivateSubscriptionModal(true)}
                >
                  <Typography className="text-[#3F6FE8] text-base cursor-pointer underline">
                    {t("reactivate")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      </MainWrapper>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openEndSubscriptionModal}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            width: "400px",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: 20,
          }}
        >
          <ErrorOutlineRoundedIcon
            sx={{
              fontSize: "100px",
              color: "#FDC01C",
              borderRadius: "50%",
              padding: "0.5rem",
            }}
          />
          <Typography>{t("end_sub_confirmation")}</Typography>
        </BootstrapDialogTitle>

        <DialogContent>
          <div className="flex justify-end gap-1">
            <Button className="capitalize" onClick={handleClose}>
              {t("close")}
            </Button>
            <LoadingButton
              className="capitalize bg-[#3F6FE8] hover:bg-[#3F6FE8] text-white"
              onClick={() => cancelSub(mySubscription?.userSubscriptionId)}
              loading={cancelSubLoading}
            >
              {t("yes")}
            </LoadingButton>
          </div>
        </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openReactivateSubscriptionModal}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            width: "400px",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: 20,
          }}
        >
          <ErrorOutlineRoundedIcon
            sx={{
              fontSize: "100px",
              color: "#FDC01C",
              borderRadius: "50%",
              padding: "0.5rem",
            }}
          />
          <Typography>{t("reactivate_confirmation")}</Typography>
        </BootstrapDialogTitle>

        <DialogContent>
          <div className="flex justify-end gap-1">
            <Button className="capitalize" onClick={handleClose}>
              {t("close")}
            </Button>
            <LoadingButton
              className="capitalize bg-[#3F6FE8] hover:bg-[#3F6FE8] text-white"
              onClick={() => reactivateSub(mySubscription?.userSubscriptionId)}
              loading={reactivateSubLoading}
            >
              {t("yes")}
            </LoadingButton>
          </div>
        </DialogContent>
      </BootstrapDialog>
      <BillingHistory />
    </>
  );
}
