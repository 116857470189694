import styled from 'styled-components';

export const CoverContainer = styled.div`
  height: 40vh;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    height: 30vh;
  }
`;

export const BackButtonContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #3f6fe80d;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  margin: auto;
`;
