import CircularIndeterminate from '../../../../../components/CircularIndeterminate/CircularIndeterminate';
import LeftSideBar from '../../../../../components/LeftSideBar/LeftSideBar';

export default function LeftSideBusinessProfile({ data, isLoading }: any) {
  return (
    <>
      {isLoading && !data ? (
        <CircularIndeterminate />
      ) : (
        <LeftSideBar data={data} />
      )}
    </>
  );
}
