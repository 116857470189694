import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../modals/components/Navbar/Navbar';
import { AppBarWrapper } from './styles';
import { ENV_NAME } from '../../config/app';

export default function Header() {
  const location = useLocation();
  const homePage = location.pathname === '/home';
  const [small, setSmall] = useState(false);
  const testEnv = ENV_NAME === 'testing';

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setSmall(window.pageYOffset > 150)
      );
    }
  }, []);

  return (
    <AppBarWrapper homePage={homePage} small={small} testEnv={testEnv}>
      <Navbar />
    </AppBarWrapper>
  );
}
