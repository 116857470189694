import React, { FC, useContext } from 'react';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '../../../../../components/BootstrapDialog/BootstrapDialog';
import { Button, DialogContent, DialogProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../../InputField/InputField';
import { useApplyForJobOfferMutation } from '../../../../../rq/hooks/useJob.mutation';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

interface ApplyForJobModalProps extends DialogProps {
  handleClose: () => void;
}

const ApplyForJobModal: FC<ApplyForJobModalProps> = ({ open, handleClose }) => {
  /**
   * hooks
   */
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { mutate: applyForJob, isLoading: applyForJobLoading } =
    useApplyForJobOfferMutation(Number(id), {
      onSuccess: () => {
        handleCloseModal();
        toast.success(t('successfully_job_for_applied'));
        navigate(-1);
      },
    });
  const { register, watch } = useForm<{ message: string }>();

  /**
   * handlers
   */
  const handleCloseModal = () => {
    handleClose();
  };

  const handleApplyForJob = () => {
    applyForJob({ message: watch('message') });
  };

  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby='customized-dialog-title'
      open={open}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          width: '100%',
        },
      }}
    >
      <BootstrapDialogTitle
        id='customized-dialog-title'
        onClose={handleCloseModal}
      >
        {t('apply_for_job_warning')}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography>{t('add_message')}</Typography>
        <InputField
          placeholder={t('contact_us.phone_number')}
          size='small'
          fullWidth
          multiline
          minRows={4}
          {...register('message')}
        />
        <div className='flex justify-end my-2 gap-2'>
          <Button className='capitalize' onClick={handleCloseModal}>
            {t('cancel')}
          </Button>
          <LoadingButton
            variant='contained'
            onClick={handleApplyForJob}
            className='capitalize bg-[#3F6FE8] hover:bg-[#3F6FE8]'
            loading={applyForJobLoading}
          >
            {t('yes')}
          </LoadingButton>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ApplyForJobModal;
