import { Button } from '@mui/material';
import styled from 'styled-components';
import img from '../../../assets/icons/job-ads-banner.png';

export const CoverContainer = styled.div`
  height: 40vh;
  width: 100%;
  position: relative;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    height: 30vh;
  }
`;

export const CoverContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CoverParagraph = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 42px;
  color: white;
  font-family: 'Montserrat';
  justify-content: center;
  font-weight: bold;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 20px;
    padding: 10px;
    text-align: center;
  }
`;

export const CoverH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  line-height: 1;
  font-family: 'Impact';
  transform: rotate(-3deg);
  text-align: center;
  //   @media screen and (max-width: 768px) {
  //     font-size: 40px;
  //   }

  //   @media screen and (max-width: 480px) {
  //     font-size: 32px;
  //   }
`;

export const CoverP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const CoverBtnWrapper = styled.div`
  margin-top: 32px;

  align-items: center;
`;

export const CoverImage = styled.img`
  width: 870px;
  height: 380px;
  object-fit: cover;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 364px;
    height: 170px;
  }
`;

export const SearchWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export const SearchButton = styled(Button)`
  text-transform: none;
  background: linear-gradient(90deg, #42e9ff -13.3%, #2e98fa 86.88%);
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
`;
