import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../lib/theme';

const {
  text_gray,
  like_button_color,
  more_button_color,
  businessCardBg,
  border_bottom_color,
} = colors;

export const UserBasicInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardHeaderWrapper = styled.div`
  border-bottom: 0.1px solid ${border_bottom_color};
  margin-bottom: 1rem;
`;

export const LikedAvatar = styled.img<{ isCandidate: number | null }>`
  height: 45px;
  width: 45px;
  border-radius: ${(props) => (props.isCandidate ? '50%' : '10px')};
  margin-right: 0.5rem;
  object-fit: cover;
`;

export const NameLocationWrapper = styled.div``;

export const NameLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat';
  &:hover {
    text-decoration: underline;
  }
`;

export const NameNoLink = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

export const Location = styled(Typography)`
  font-size: 14px;
  color: ${text_gray};
`;

export const CardVideoWrapper = styled.div`
  position: relative;

  margin-bottom: 0.5rem;
  video {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 20px;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  margin-top: 0.3rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export const Label = styled(Typography)`
  font-size: 12px;
  color: ${text_gray};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LikeButton = styled(LoadingButton)<{
  isCandidate: boolean;
  likedFromMe: boolean;
}>`
  // width: 90%;
  flex: 1;
  text-transform: none;
  border-radius: 10px;
  font-size: 14px;
  background-color: ${(props) =>
    props.likedFromMe ? like_button_color : more_button_color};
  color: ${(props) => (props.likedFromMe ? '' : '#65676B')};
  margin-right: 1rem;
`;

export const MoreButton = styled(Button)`
  background-color: ${more_button_color};
  border-radius: 10px;
`;

export const AvatarWrapper = styled.div<{ isCandidate: number | null }>`
  border: 1px solid transparent;
  background-color: ${businessCardBg};
  border-radius: ${(props) => (props.isCandidate ? '50%' : '10px')};
  min-width: 45px;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

export const VideoWrapper = styled.video`
  border-radius: 15px;
  width: 100%;
  height: 150px;
  margin-bottom: 0.5rem;
  object-fit: fill;
`;

export const FetchDiv = styled.div`
  width: 100%;
  text-align: center;
`;

export const TypoFetch = styled(Typography)``;

export const NoCard = styled(Typography)`
  font-size: 14px;
  text-align: center;
  color: ${text_gray};
`;

export const MoreButtonWrapper = styled(Box)``;

export const NameLinkBusiness = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PlayerIconWrapper = styled(Box)`
  position: absolute;
  z-index: 10;
  color: white;
  display: inline-block;
  padding: 5px;
  top: 35%;
  left: 45%;
  background: #000000;
  opacity: 0.7;
  border-radius: 50%;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    left: 43%;
  }
`;
