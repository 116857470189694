import { useContext, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { GenericProfileUpload } from "../../../../../../../components/GenericProfileUpload/GenericProfileUpload";
import Select from "../../../../../../../components/Select/Select";
import { EditBusinessProfileModel } from "../../../../../../../interfaces/types/User";
import { AuthContext } from "../../../../../../../lib/context/AuthContext";
import {
  useAddEditProfilePicture,
  useDeleteBusinessProfilePicture,
  useEditBusinessProfileDetails,
} from "../../../../../../../rq/hooks/useEditProfile.mutation";
import { useGetAllCountriesQuery } from "../../../../../../../rq/hooks/useQueries";
import { EditBusinessProfileFormSchema } from "../../../../../../../validators/EditProfile.validator";
import { InputField } from "../../../../../InputField/InputField";
import {
  SaveChangesButton,
  ButtonsWrapper,
} from "../../../../student-dashboard/EditStudentProfile/ProfileDetails/EditProfileDetails/styles";
import { DiscardButton } from "../../../../student-dashboard/EditStudentProfile/styles";
import {
  MainWrapper,
  Title,
  TitleWrapper,
  FormPart,
  FormPropWrapper,
  FormWrapper,
} from "../../../../styles";

export default function EditBusinessProfileDetails() {
  //hooks
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  // hook form
  const methods = useForm<EditBusinessProfileModel>({
    resolver: yupResolver(EditBusinessProfileFormSchema(t)),
  });

  const i18n_countries = {
    Belgium: t("countries.BEL"),
    France: t("countries.FRA"),
    Netherlands: t("countries.NLD"),
    Luxembourg: t("countries.LUX"),
    Kosovo: "Kosovo",
  };

  const {
    trigger,
    watch,
    getValues,
    setValue: setProfilePicture,
  } = useForm<{
    photo: File | string | null;
  }>({
    // resolver: joiResolver(ProfilePictureSchema),
    defaultValues: {
      photo: user?.profilePicture || "",
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      isDirty,
      errors: { companyName, companyVat, companyAddress, postalCode },
    },
  } = methods;

  //react mutations
  const { mutate: addEditProfilePicture, isLoading: editIsLoading } =
    useAddEditProfilePicture();

  const {
    mutate: deleteBusinessProfilePicture,
    isLoading: deleteProfilePictureBusiness,
  } = useDeleteBusinessProfilePicture({
    onSuccess: () => {
      toast.success("Deletion was successful");
      queryClient.fetchQuery(["businessProfile"]);
      queryClient.fetchQuery(["sideBarBusinessProfile"]);
    },
  });

  const { mutate: editBusinessProfile, isLoading } =
    useEditBusinessProfileDetails({
      onSuccess: () => {
        toast.success(t("successMessage.no_admin_verification"));
        queryClient.fetchQuery(["businessProfile"]);
        queryClient.fetchQuery(["sideBarBusinessProfile"]);
        setTimeout(() => reset(), 1000);
      },
    });
  //react queries
  const { data: countries, isSuccess } = useGetAllCountriesQuery();
  const internationalizedCountries = isSuccess
    ? countries.map(
        ({
          id,
          name,
          countryCode,
        }: {
          id: number;
          name: string;
          countryCode: string;
        }) => ({
          id,
          name: i18n_countries[name as keyof typeof i18n_countries],
          countryCode,
        })
      )
    : [];

  //handlers
  const onSubmit = (data: EditBusinessProfileModel) => {
    const formData: any = new FormData();
    formData.append("companyName", data.companyName);
    formData.append("companyVat", data.companyVat);
    formData.append("companyAddress", data.companyAddress);
    formData.append("numberOfWorkers", data.numberOfWorkers);
    formData.append("phone", data.phone);
    formData.append("companySite", data.companySite);
    if (data.countryId) formData.append("countryId", data.countryId);
    formData.append("email", data.email);
    formData.append("postalCode", data.postalCode);
    editBusinessProfile(formData);
  };

  const handleSelectProfilePicture = async (file: File | string | null) => {
    if (!file) deleteBusinessProfilePicture();
    else {
      setProfilePicture("photo", file, { shouldValidate: true });
      const triggered = await trigger("photo");
      if (!triggered) return;

      const formData = new FormData();
      if (file) formData.append("photo", file);
      addEditProfilePicture(formData as unknown as File, {
        onSuccess: () => {
          queryClient.fetchQuery(["businessProfile"]);
          queryClient.fetchQuery(["sideBarBusinessProfile"]);
        },
      });
    }
  };

  //effects
  useEffect(() => {
    reset({ countryId: user?.countryId });
  }, [reset]);
  return (
    <MainWrapper>
      <TitleWrapper>
        <Title>{t("editProfile.title")}</Title>
      </TitleWrapper>
      {user && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormWrapper>
              <FormPart>
                <FormPropWrapper>{t("editProfile.avatar")}</FormPropWrapper>
                <GenericProfileUpload
                  deleteLoading={deleteProfilePictureBusiness}
                  updateLoading={editIsLoading}
                  selectedFile={getValues("photo")}
                  onSelect={handleSelectProfilePicture}
                  canDelete={!!watch("photo")}
                />
              </FormPart>
              <FormPart>
                <FormPropWrapper>
                  {t("editProfile.edit_business_profile.company")}
                </FormPropWrapper>
                <InputField
                  placeholder={t("editProfile.edit_business_profile.company")}
                  type="input"
                  variant="standard"
                  InputProps={{
                    sx: {
                      backgroundColor: "#F4F7F9",
                      borderRadius: "10px",
                      padding: "0 10px",
                    },
                    disableUnderline: true,
                  }}
                  defaultValue={user.company}
                  {...register("companyName")}
                  fieldError={companyName}
                  fullWidth
                />
              </FormPart>
              <FormPart>
                <FormPropWrapper>
                  {t("editProfile.edit_business_profile.company_vat")}
                </FormPropWrapper>
                <InputField
                  placeholder={t(
                    "editProfile.edit_business_profile.company_vat"
                  )}
                  type="input"
                  defaultValue={user.companyVat}
                  variant="standard"
                  InputProps={{
                    sx: {
                      backgroundColor: "#F4F7F9",
                      borderRadius: "10px",
                      padding: "0 10px",
                    },
                    disableUnderline: true,
                  }}
                  {...register("companyVat")}
                  fieldError={companyVat}
                  fullWidth
                />
              </FormPart>
              <FormPart>
                <FormPropWrapper>
                  {t("editProfile.edit_business_profile.company_address")}
                </FormPropWrapper>
                <InputField
                  placeholder={t(
                    "editProfile.edit_business_profile.company_address"
                  )}
                  type="input"
                  defaultValue={user.companyAddress}
                  variant="standard"
                  InputProps={{
                    sx: {
                      backgroundColor: "#F4F7F9",
                      borderRadius: "10px",
                      padding: "0 10px",
                    },
                    disableUnderline: true,
                  }}
                  {...register("companyAddress")}
                  fieldError={companyAddress}
                  fullWidth
                />
              </FormPart>
              <FormPart>
                <FormPropWrapper>Postal Code</FormPropWrapper>
                <InputField
                  placeholder="Postal Code"
                  type="input"
                  defaultValue={user.postalCode}
                  variant="standard"
                  InputProps={{
                    sx: {
                      backgroundColor: "#F4F7F9",
                      borderRadius: "10px",
                      padding: "0 10px",
                    },
                    disableUnderline: true,
                  }}
                  {...register("postalCode")}
                  fieldError={postalCode}
                  fullWidth
                />
              </FormPart>
              <FormPart>
                <FormPropWrapper>
                  {t("editProfile.edit_business_profile.number_of_workers")}
                </FormPropWrapper>
                <InputField
                  placeholder={t(
                    "editProfile.edit_business_profile.number_of_workers"
                  )}
                  type="input"
                  defaultValue={user.numberOfWorkers}
                  variant="standard"
                  InputProps={{
                    sx: {
                      backgroundColor: "#F4F7F9",
                      borderRadius: "10px",
                      padding: "0 10px",
                    },
                    disableUnderline: true,
                  }}
                  {...register("numberOfWorkers")}
                  fieldError={companyName}
                  fullWidth
                />
              </FormPart>
              <FormPart>
                <FormPropWrapper>
                  {t("editProfile.edit_business_profile.contact_phone")}
                </FormPropWrapper>
                <InputField
                  placeholder={t(
                    "editProfile.edit_business_profile.contact_phone"
                  )}
                  type="input"
                  defaultValue={user.contactNumber}
                  variant="standard"
                  InputProps={{
                    sx: {
                      backgroundColor: "#F4F7F9",
                      borderRadius: "10px",
                      padding: "0 10px",
                    },
                    disableUnderline: true,
                  }}
                  {...register("phone")}
                  fieldError={companyName}
                  fullWidth
                />
              </FormPart>
              <FormPart>
                <FormPropWrapper>
                  {t("editProfile.edit_business_profile.company_site")}
                </FormPropWrapper>
                <InputField
                  placeholder={t(
                    "editProfile.edit_business_profile.company_site"
                  )}
                  type="input"
                  defaultValue={user.companySite}
                  variant="standard"
                  InputProps={{
                    sx: {
                      backgroundColor: "#F4F7F9",
                      borderRadius: "10px",
                      padding: "0 10px",
                    },
                    disableUnderline: true,
                  }}
                  {...register("companySite")}
                  fieldError={undefined}
                  fullWidth
                />
              </FormPart>
              <FormPart>
                <FormPropWrapper>{t("editProfile.country")}</FormPropWrapper>
                <FormControl fullWidth size="small">
                  <Select
                    defaultValue={user.countryId}
                    options={internationalizedCountries}
                    variant="standard"
                    disableUnderline
                    inputProps={{
                      sx: {
                        backgroundColor: "#F4F7F9",
                        borderRadius: "10px",
                        padding: "3px 10px",
                      },
                    }}
                    {...register("countryId")}
                  />
                </FormControl>
              </FormPart>
              <FormPart>
                <FormPropWrapper>{t("editProfile.email")}</FormPropWrapper>
                <InputField
                  placeholder={t("editProfile.email")}
                  type="input"
                  disabled
                  defaultValue={user.email}
                  variant="standard"
                  InputProps={{
                    sx: {
                      backgroundColor: "#F4F7F9",
                      borderRadius: "10px",
                      padding: "0 10px",
                    },
                    disableUnderline: true,
                  }}
                  {...register("email")}
                  fieldError={undefined}
                  fullWidth
                />
              </FormPart>
            </FormWrapper>
            <ButtonsWrapper>
              <DiscardButton
                disabled={!isDirty}
                variant="contained"
                onClick={() => reset()}
              >
                {t("discard")}
              </DiscardButton>
              <SaveChangesButton
                disabled={!isDirty}
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                {t("save_changes")}
              </SaveChangesButton>
            </ButtonsWrapper>
          </form>
        </FormProvider>
      )}
    </MainWrapper>
  );
}
