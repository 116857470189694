import { useState } from 'react';
import {
  FileWrapper,
  UploadIcon,
  FileName,
  PreviewFile,
} from '../../EditStudentProfile/styles';
import cv_icon from '../../../../../../assets/icons/cv-icon.png';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useDeleteDigitalBookMutation } from '../../../../../../rq/hooks/useEditProfile.mutation';
import PdfViewer from '../../../../../../components/PdfViewer/PdfViewer';

export default function EditDigitalBook({ userDigitalBook }: any) {
  const regex = /[^\/]+$/;
  const queryClient = useQueryClient();

  const { mutate: deleteMotivationLetter, isLoading } =
    useDeleteDigitalBookMutation({
      onSuccess: () => {
        queryClient.fetchQuery(['profile']);
        toast.success('Deletion was successfull');
      },
    });
  const handleDeleteMotivationLetter = () =>
    deleteMotivationLetter({ fileId: userDigitalBook.candidateFileId });

  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <FileWrapper>
        <UploadIcon src={cv_icon} />
        <FileName>{userDigitalBook.filePath.match(regex)}</FileName>
        <PreviewFile onClick={() => setOpen(true)}>(preview)</PreviewFile>
      </FileWrapper>
      {isLoading ? (
        <LoadingButton loading />
      ) : (
        <Tooltip title='Delete motivation letter'>
          <IconButton>
            <DeleteIcon onClick={handleDeleteMotivationLetter} />
          </IconButton>
        </Tooltip>
      )}
      <PdfViewer
        name='Digital Book Preview'
        open={open}
        setOpen={setOpen}
        file={userDigitalBook.filePath}
      />
    </div>
  );
}
