import {
  Paper,
  Box,
  Grid,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SignUpTitleWrapper,
  SignUpTypo,
  RegisterButton,
  Label,
} from '../RegisterStudent/styles';
import JobsAds from '../../../../components/sharedComponents/JobsAds/JobsAds';
import { BusinessInputs } from '../../../../interfaces/types/User';
import { useRegisterBusinessMutation } from '../../../../rq/hooks/useAuth.mutation';
import UploadVideo from '../UploadVideo/UploadVideo';
import BusinessBasicInformation from '../BusinessBasicInformation/BusinessBasicInformation';
import BusinessImportantInformation from '../BusinessImportantInformation/BusinessImportantInformation';
import { UploadImage } from '../UploadImage/UploadImage';
import { RegisterBusinessFormSchema } from '../../../../validators/Register.validator';
import { useNavigate } from 'react-router-dom';
import {
  readFromStorage,
  writeToStorage,
} from '../../../../utils/storage/storage';
import { Trans, useTranslation } from 'react-i18next';
import PrivacyPolicy from '../../../../components/PrivacyPolicy/PrivacyPolicy';

export default function RegisterBusiness() {
  const { t } = useTranslation();

  const methods = useForm<BusinessInputs>({
    resolver: yupResolver(RegisterBusinessFormSchema(t)),
  });

  // destructures
  const { register, handleSubmit } = methods;

  // react-mutations
  const { mutate: registerMutation, isLoading } = useRegisterBusinessMutation();
  const navigate = useNavigate();
  const lng = readFromStorage('i18nextLng');

  // handlers
  const onSubmit = async (data: BusinessInputs) => {
    const formData: any = new FormData();
    formData.append('name', data.name);
    formData.append('businessTypeId', data.businessTypeId);
    formData.append('numberOfWorkers', data.numberOfWorkers);
    formData.append('vat', data.vat);
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('address', data.address);
    formData.append('phoneNumber', data.phoneNumber);
    formData.append('postalCode', data.postalCode);

    if (lng === 'en') {
      formData.append('languageId', 1);
    } else {
      formData.append('languageId', 2);
    }
    if (data.profilePicture) {
      formData.append('profilePicture', data.profilePicture);
    }
    formData.append('video', data.video?.[0], 'video.mp4');

    registerMutation(formData, {
      onSuccess: (res) => {
        navigate(`/confirm-email?email=${encodeURIComponent(data.email)}`);
        writeToStorage('email', data.email);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <JobsAds />
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6}>
          <Box
            sx={{
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                component='form'
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <SignUpTitleWrapper>
                  <SignUpTypo variant='h5'>
                    {t('auth.sign_up.title')}
                  </SignUpTypo>
                  <Typography component='p' className='font-montserrat'>
                    {t('auth.sign_up.description')}
                  </Typography>
                </SignUpTitleWrapper>
                <UploadImage />
                <BusinessBasicInformation />
                <BusinessImportantInformation />
                <UploadVideo />
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={<Label>{t('auth.sign_up.recieve_news')}</Label>}
                    {...register('receiveNews')}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      <Label>
                        <Trans
                          i18nKey='auth.sign_up.agree_terms'
                          values={{
                            terms: `<terms>${t('auth.sign_up.terms')}</terms>`,
                            policy: `<a>${t(
                              'auth.sign_up.privacy_policy'
                            )}</a>`,
                          }}
                          components={{
                            a: (
                              <PrivacyPolicy
                                name={t('auth.sign_up.privacy_policy')}
                              />
                            ),
                            terms: (
                              <PrivacyPolicy name={t('auth.sign_up.terms')} />
                            ),
                          }}
                        />
                      </Label>
                    }
                    {...register('acceptTerms')}
                  />
                </FormGroup>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6}>
                    <RegisterButton
                      loading={isLoading}
                      type='submit'
                      variant='contained'
                    >
                      {t('auth.sign_up.create_account_button')}
                    </RegisterButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
