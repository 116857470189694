import React, { useContext, useEffect, useState } from 'react';
import {
  ChoosePlanButton,
  ExclusiveVAT,
  FieldSet,
  Legend,
  ListItem,
  ListWrapper,
  PackageCredits,
  PackageListWrapper,
  PackagePrice,
  PackagePriceWrapper,
  PackageTitle,
  PackageWrapper,
} from '../styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentContext } from '../../../../../lib/context/PaymentContext';
import { useStripeCheckoutQuery } from '../../../../../rq/hooks/useQueries';
import StripePayment from '../../../layouts/business-dashboard/HistoryOfPackage/Plans/StripePayment/StripePayment';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { STRIPE } from '../../../../../config/app';
import { usePackageSubscriptionMutation } from '../../../../../rq/hooks/useEditProfile.mutation';
const stripePromise = loadStripe(STRIPE);

export default function CreditPurchasePlan({ identifier, plan }: any) {
  const { name, id, price, points, priceWithVat, isSubscription } = plan;
  const { t } = useTranslation();
  const packageProfits = [
    {
      id: 1,
      title: `${t('credits.advantages.one')}`,
    },
    {
      id: 2,
      title: `${t('credits.advantages.two')}`,
    },
    {
      id: 3,
      title: `${t('credits.advantages.three')}`,
    },
    {
      id: 4,
      title: `${t('credits.advantages.four')}`,
    },
  ];
  const { clientSecret, setClientSecret } = useContext(PaymentContext);

  const options = {
    clientSecret,
  };
  const [openPayModal, setOpenPayModal] = useState<boolean>(false);
  const {
    mutate: getSubscription,
    isLoading: getSubscriptionLoading,
    data: subscriptionData,
    isSuccess: subscriptionSuccess,
  } = usePackageSubscriptionMutation(id, {
    onSuccess: () => setOpenPayModal(true),
  });

  const handleOpenModal = () => {
    if (isSubscription) {
      getSubscription(id);
    } else {
      setOpenPayModal(true);
    }
  };

  const { data, isLoading, isSuccess } = useStripeCheckoutQuery(id, {
    enabled: openPayModal && isSubscription === false,
  });

  useEffect(() => {
    if (subscriptionData) {
      setClientSecret(subscriptionData as string);
    }
    if (data) {
      setClientSecret(data);
    }
  }, [isSuccess, subscriptionSuccess]);
  return (
    <>
      {name === 'Pro' ? (
        <FieldSet>
          <Legend>{t('most_popular')}</Legend>
          <PackageTitle>{name}</PackageTitle>
          <PackagePriceWrapper>
            <PackagePrice>€{price}</PackagePrice>
            <PackageCredits>/{points}Cr.</PackageCredits>
          </PackagePriceWrapper>
          <ExclusiveVAT>{t('credits.price_without_vat')}</ExclusiveVAT>
          <PackageListWrapper>
            <ol>
              {packageProfits.map((pF) => (
                <ListWrapper key={pF.id}>
                  <span
                    style={{
                      background: 'rgba(72, 123, 217, 0.1',
                      borderRadius: '50%',
                    }}
                  >
                    <CheckIcon
                      fontSize='inherit'
                      sx={{
                        color: '#487BD9',
                      }}
                    />
                  </span>
                  <ListItem> {pF.title}</ListItem>
                </ListWrapper>
              ))}
            </ol>
          </PackageListWrapper>
          <ChoosePlanButton
            variant='contained'
            onClick={handleOpenModal}
            name={name}
          >
            {t('choose_plan')}
          </ChoosePlanButton>
        </FieldSet>
      ) : (
        <PackageWrapper>
          <PackageTitle>{name}</PackageTitle>
          <PackagePriceWrapper>
            <PackagePrice>€{price}</PackagePrice>
            <PackageCredits>/{points}Cr.</PackageCredits>
          </PackagePriceWrapper>
          <ExclusiveVAT>{t('credits.price_without_vat')}</ExclusiveVAT>
          <PackageListWrapper>
            <ol>
              {packageProfits.map((pF) => (
                <ListWrapper key={pF.id}>
                  <span
                    style={{
                      background: 'rgba(153, 154, 153, 0.1)',
                      borderRadius: '50%',
                    }}
                  >
                    <CheckIcon
                      fontSize='inherit'
                      sx={{
                        color: '#999A99',
                      }}
                    />
                  </span>
                  <ListItem> {pF.title}</ListItem>
                </ListWrapper>
              ))}
            </ol>
          </PackageListWrapper>
          <ChoosePlanButton onClick={handleOpenModal} name={name}>
            {t('choose_plan')}
          </ChoosePlanButton>
        </PackageWrapper>
      )}
      {clientSecret && (!isLoading || !getSubscriptionLoading) && (
        <Elements stripe={stripePromise} options={options} key={clientSecret}>
          <StripePayment
            planId={id}
            openPayModal={openPayModal}
            setOpenPayModal={setOpenPayModal}
            price={price}
            points={points}
            priceWithVat={priceWithVat}
          />
        </Elements>
      )}
    </>
  );
}
