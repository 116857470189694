import { Avatar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { MainWrapper } from '../../styles';
import { Link } from 'react-router-dom';
import { BusinessRounded } from '@mui/icons-material';
import { JobAdsModel } from '../../../../../interfaces/JobOffers.model';
import dayjs from 'dayjs';

const AppliedJob = ({ job }: { job: JobAdsModel }) => {
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <div className='flex justify-between '>
        <div className='flex gap-1'>
          {job.businessLogoPath ? (
            <Avatar src={job.businessLogoPath} variant='rounded' />
          ) : (
            <Avatar src='' variant='rounded'>
              <BusinessRounded />
            </Avatar>
          )}
          <div className='flex flex-col'>
            <Typography className='text-[#9D9D9D] text-sm'>
              {job.businessName}
            </Typography>
            <Typography className='font-bold text-sm'>{job.title}</Typography>
            <Typography className='text-[#4A4D4B] text-sm'>
              {job.employmentTypes.join(', ')}
            </Typography>
            <div className='flex items-center gap-1'>
              <CalendarTodayOutlinedIcon className='fill-[#C6C6C6] text-sm' />
              <Typography className='text-[#4A4D4B] text-sm'>
                {t('published_on')}{' '}
                {dayjs(job.publishDate).format('DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
        </div>
        <Link to={`/job-offer/${job.id}`} className='flex items-center gap-1'>
          <Typography className='text-sm text-[#3F6FE8]'>
            {t('view_details')}
          </Typography>
          <ArrowForwardIcon className='text-sm fill-[#3F6FE8]' />
        </Link>
      </div>
    </MainWrapper>
  );
};

export default AppliedJob;
