import * as yup from 'yup';

export const AddJobSchema = (t: any) =>
  yup.object({
    title: yup.string().required(t('formErrors.required')),
    enterpriseDescription: yup.string().nullable(),
    jobDescription: yup.string().required(t('formErrors.required')),
    functionDescription: yup.string().nullable(),
    offerDescription: yup.string().nullable(),
    teaser: yup.string().nullable(),
    employmentTypes: yup
      .array()
      .min(1, 'Select at least one option of your interest'),
  });
